import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  CircularProgress,
  List,
  ListItemButton,
  Menu,
} from "@mui/material";
import { CheckMark, LogoIcon, Plus, SortIcon } from "../../icons";
import FullPageLoader from "../Common/FullPageLoader";
import { SESSION_AUTH } from "../../common/auth";
import { USER_SERVICES } from "../../services";

const HeaderAccounts = (props) => {
  const { setLoginVerify } = props;
  const { isUserSession, UPDATE_USER_SESSION, LOGOUT_USER_SESSION } =
    SESSION_AUTH();

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [headerMenuEl, setHeaderMenuEl] = useState(null);
  const headerMenuOpen = Boolean(headerMenuEl);
  const [userAccountsList, setUserAccountsList] = useState(null);

  const navigate = useNavigate();
  const headerMenuOpenReq = (event) => {
    if (!userData?.isVerify) {
      setLoginVerify(true);
      return;
    }
    setHeaderMenuEl(event.currentTarget);
    if (!userAccountsList) getUserAccountsList();
  };
  const headerMenuClose = () => {
    setHeaderMenuEl(null);
  };

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      setUserAccountsList("");
    }
    return () => null;
  }, [isUserSession]);

  if (!isUserSession) {
    return;
  }
  const getUserAccountsList = () => {
    try {
      USER_SERVICES.getAccountList({
        userId: userData?.userId,
        org: userData?.org?.id,
      })
        .then((data) => {
          if (data.code === 200) {
            if (data?.data && data?.data?.length > 0) {
              setUserAccountsList(data?.data);
            }
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            toast(data.message);
            setHeaderMenuEl(null);
          }
        })
        .catch((err) => {
          toast(err.message);
        });
    } catch (err) {
      toast(err.message);
    }
  };
  const createUserOrgAccount = () => {
    try {
      setLoading(true);
      headerMenuClose();
      USER_SERVICES.createAccout({
        userId: userData?.userId,
        org: userData?.org?.id,
      })
        .then((data) => {
          if (data.code === 200) {
            setUserAccountsList("");
            switchUserAccount(data.data);
            return;
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            toast(data.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          toast(err.message);
          setLoading(false);
        });
    } catch (err) {
      toast(err.message);
    }
  };

  const switchUserAccount = (item) => {
    if (item?.accountId === userData?.org?.id) return;
    setLoading(true);
    headerMenuClose();
    USER_SERVICES.switchAccount({
      userId: userData?.userId,
      org: userData?.org?.id,
      switch: item?.accountId,
    })
      .then((data) => {
        if (data.code === 200) {
          UPDATE_USER_SESSION({ org: data?.data });
          navigate("/R_C_P", { state: { replace: true } });
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          toast(data.message);
        }
      })
      .catch((error) => {
        toast(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      {loading ? <FullPageLoader /> : null}
      <div
        className={`videos-layout-header-right-item-accounts-btn ${
          headerMenuOpen ? "active" : ""
        }`}
        id="header-accounts-menu-button"
        aria-controls={headerMenuOpen ? "header-accounts-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={headerMenuOpen ? "true" : undefined}
        onClick={headerMenuOpenReq}
      >
        <div
          className="videos-layout-header-right-item-accounts-btn-logo"
          style={{
            backgroundColor: userData?.org?.orgLogo
              ? userData?.org?.orgLogoColor
              : "",
          }}
        >
          {userData?.org?.orgLogo ? (
            <img src={userData?.org?.orgLogo} alt="logo" />
          ) : (
            <LogoIcon />
          )}
        </div>
        <div
          className="videos-layout-header-right-item-accounts-btn-name"
          translate="no"
        >
          {userData?.org?.orgName}
        </div>
        <div className="videos-layout-header-right-item-accounts-btn-sort">
          <SortIcon />
        </div>
      </div>

      <Menu
        id="header-accounts-menu"
        anchorEl={headerMenuEl}
        open={headerMenuOpen}
        onClose={headerMenuClose}
        MenuListProps={{
          "aria-labelledby": "header-accounts-menu-button",
        }}
        className="videos-layout-header-right-item-dropdown videos-layout-header-right-item-accounts"
      >
        <List
          component="nav"
          className="videos-layout-header-right-item-accounts-list"
        >
          {userAccountsList ? (
            userAccountsList?.map((item, key) => (
              <ListItemButton
                key={key}
                className="videos-layout-header-right-item-accounts-list-item"
                onClick={() => {
                  switchUserAccount(item);
                }}
                title={item?.orgName}
              >
                <div className="videos-layout-header-right-item-accounts-list-item-logo">
                  {item?.orgLogo ? (
                    <img src={item.orgLogo} alt="logo" />
                  ) : (
                    <LogoIcon />
                  )}
                </div>
                <div
                  className="videos-layout-header-right-item-accounts-list-item-name"
                  translate="no"
                >
                  {item?.orgName}
                </div>
                {item.accountId === userData?.org?.id && (
                  <div className="videos-layout-header-right-item-accounts-list-item-check">
                    <CheckMark />
                  </div>
                )}
              </ListItemButton>
            ))
          ) : (
            <Box className="videos-layout-header-right-item-accounts-list-loader">
              <CircularProgress />
            </Box>
          )}
        </List>
        {userAccountsList ? (
          <Box
            onClick={createUserOrgAccount}
            className="videos-layout-header-right-item-accounts-footer"
          >
            <Plus /> Create Org
          </Box>
        ) : null}
      </Menu>
    </>
  );
};

export default HeaderAccounts;
