import React from "react";
import VideoUploadButtonUrl from "../Uploader/VideoUploadButtonUrl";
import DashboardList from "./DashboardList";
import "./style.scss";

const Dashboard = () => {
  return (
    <div className="videos-layout-content-container">
      <VideoUploadButtonUrl classes="videos-layout-list-uploadBox" />
      <DashboardList />
    </div>
  );
};

export default Dashboard;
