import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { createTheme, ThemeProvider } from "@mui/material";
import App from "./App";
import { GlobalProvider } from "./GlobalContext";
import reportWebVitals from "./reportWebVitals";
import "./assets/css/base.scss";
import "./index.css";
import "./assets/css/plyr.css";
import "./assets/css/style.css";
import "./assets/css/style.scss";
import "react-toastify/dist/ReactToastify.css";
const theme = createTheme({
  palette: {
    mode: "dark",

    primary: {
      main: "#e3c050",
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("videos-directory"));

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <GlobalProvider>
          <App />
        </GlobalProvider>
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
