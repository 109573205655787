import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useGlobalValue } from "../../GlobalContext";
import { UploadIcon } from "../../icons";
import { SESSION_AUTH } from "../../common/auth";
import { VIDEO_TYPE_ALLOW } from "../../common/constants";

const VideoUploadDrag = (props) => {
  const { setLoginRequest, videoUploadRequest } = useGlobalValue();
  const { classes, onCallBack } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [file, setFile] = useState(null);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
    }
    return () => null;
  }, [isUserSession]);

  const handleChange = (file) => {
    setFile(file);
    if (!userData) {
      setLoginRequest(true);
    }
  };

  useEffect(() => {
    if (file && userData) {
      videoUploadRequest(file, "file", userData, LOGOUT_USER_SESSION);
      setFile("");
      navigate("/dashboard");
      if (onCallBack) {
        onCallBack();
      }
    }
    return () => null;
  }, [
    file,
    userData,
    videoUploadRequest,
    LOGOUT_USER_SESSION,
    navigate,
    onCallBack,
  ]);

  return (
    <div className={`videos-uploadBox videos-uploadBox-withDrag ${classes}`}>
      <FileUploader
        handleChange={handleChange}
        name="file"
        types={VIDEO_TYPE_ALLOW}
      />
      <div className="videos-uploadBox-withDrag-content">
        <div className="videos-uploadBox-withDrag-content-heading">
          Select video to upload
        </div>
        <div className="videos-uploadBox-withDrag-content-text">
          Or drag and drop video file
        </div>
        <Button
          variant="outlined"
          className="videos-btn videos-btn-md videos-btn-primary"
          type="button"
          startIcon={<UploadIcon />}
        >
          upload video for free
        </Button>
        <div className="videos-uploadBox-withDrag-content-info">
          *No credit card required
        </div>
      </div>
    </div>
  );
};

export default VideoUploadDrag;
