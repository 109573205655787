import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { UploadIcon } from "../../icons";
import FullPageLoader from "../Common/FullPageLoader";
import ShareModal from "../Models/ShareModal";
import VideoCreator from "./VideoCreator";
import VideoDownload from "./VideoDownload";
import VideoLike from "./VideoLike";
import { SESSION_AUTH } from "../../common/auth";
import { VIDEO_SERVICES } from "../../services";
import { STATUS_LIST } from "../../common/constants";

const VideoStatic = (props) => {
  const { videoData } = props;
  const [shareRequest, setShareRequest] = useState(false);
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [staticData, setStaticData] = useState("");

  const dataFetched = useRef(false);
  const [loading, setLoading] = useState(true);

  const shareVideo = () => {
    setShareRequest(true);
  };

  useEffect(() => {
    if (!videoData?._id || dataFetched?.current) return;
    dataFetched.current = true;
    setLoading(true);
    VIDEO_SERVICES.static({
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
      id: videoData?._id,
    })
      .then((data) => {
        if (data.code === 200) {
          setStaticData(data.data || "");
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          toast(data.message);
        }
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataFetched, videoData, isUserSession, LOGOUT_USER_SESSION]);

  return (
    <React.Fragment>
      {loading ? (
        <FullPageLoader normal={true} />
      ) : (
        <React.Fragment>
          <div className="videos-layout-video-detail-statics">
            <VideoCreator
              classess="videos-layout-video-detail-statics-creator"
              creatorData={staticData?.creator}
              subscribeData={staticData?.subscribe}
              videoId={videoData?._id}
            />
            <div className="videos-layout-video-detail-statics-buttons">
              <VideoLike likeData={staticData?.like} videoId={videoData?._id} />
              <VideoDownload
                videoId={videoData?._id}
                enbableDownload={
                  videoData?.status === STATUS_LIST?.active?.value
                }
              />
              <Button
                variant="outlined"
                className="videos-btn videos-btn-sm videos-btn-dark"
                startIcon={<UploadIcon />}
                type="button"
                onClick={shareVideo}
                disabled={videoData?.status !== STATUS_LIST?.active?.value}
              >
                Share
              </Button>
            </div>
          </div>
          <div className="videos-layout-video-detail-views">
            <span className="mr-10"> {staticData?.views} views</span>
            <span>{staticData?.createdAt}</span>
          </div>
        </React.Fragment>
      )}
      {shareRequest && (
        <ShareModal
          request={shareRequest}
          videoLink={videoData?.videoLink}
          title={videoData?.title}
          embedUrl={`${process.env.REACT_APP_PLATFORM_URL}/embed/${videoData?._id}`}
          cancel={() => {
            setShareRequest(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default VideoStatic;
