import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGlobalValue } from "../../GlobalContext";
import { UserIcon } from "../../icons";
import { SESSION_AUTH } from "../../common/auth";
import { VIDEO_SERVICES } from "../../services";

const VideoCreator = (props) => {
  const { videoId, creatorData, subscribeData, classess } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [loading, setLoading] = useState(false);
  const { setLoginRequest } = useGlobalValue();

  const [isSubscribe, setIsSubscribe] = useState(false);
  const [countSubscribe, setCountSubscribe] = useState(0);

  useEffect(() => {
    setIsSubscribe(subscribeData?.isSubscribe);
    setCountSubscribe(subscribeData?.count);
  }, [subscribeData]);

  const videoSubscribe = (type) => {
    if (loading) return;
    if (!isUserSession) return setLoginRequest(true);
    setLoading(true);
    setIsSubscribe(type);
    VIDEO_SERVICES.subscribe({
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
      id: videoId,
      type: type ? "subscribe" : "unsubscribe",
    })
      .then((data) => {
        if (data.code === 200) {
          setIsSubscribe(data?.data?.isSubscribe || false);
          setCountSubscribe(data?.data?.count || 0);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          setIsSubscribe(false);
          toast(data.message);
        }
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className={`videos-creator ${classess}`}>
        <div className="videos-creator-img">
          {creatorData?.profileImage ? (
            <img src={creatorData?.profileImage} alt="6ix user" />
          ) : (
            <UserIcon />
          )}
        </div>
        <div className="videos-creator-info">
          <div className="videos-creator-info-title">{creatorData?.name}</div>
          <div className="videos-creator-info-text">
            {countSubscribe} Subscriber
          </div>
        </div>
        {!creatorData?.isCreator && (
          <button
            type="button"
            className={
              isSubscribe ? "videos-subscribe active" : "videos-subscribe"
            }
            onClick={() => videoSubscribe(!isSubscribe)}
          >
            {isSubscribe ? "Subscribed" : "Subscribe"}
          </button>
        )}
      </div>
    </React.Fragment>
  );
};

export default VideoCreator;
