import React from "react";
export function DeleteIcon(props) {
  return props.type === "round" ? (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="22" fill="#E64135" fillOpacity="0.3" />
      <path
        d="M14.2988 16.501H16.0099H29.6988"
        stroke="#E64135"
        strokeWidth="1.74577"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5745 16.4981V14.7871C18.5745 14.3333 18.7548 13.8982 19.0757 13.5773C19.3965 13.2564 19.8318 13.0762 20.2856 13.0762H23.7078C24.1616 13.0762 24.5969 13.2564 24.9177 13.5773C25.2386 13.8982 25.4189 14.3333 25.4189 14.7871V16.4981M27.9856 16.4981V28.4747C27.9856 28.9284 27.8053 29.3636 27.4844 29.6845C27.1635 30.0054 26.7283 30.1856 26.2745 30.1856H17.7189C17.2651 30.1856 16.8299 30.0054 16.509 29.6845C16.1881 29.3636 16.0078 28.9284 16.0078 28.4747V16.4981H27.9856Z"
        stroke="#E64135"
        strokeWidth="1.74577"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.7002 4.50031H4.1002H15.3002"
        stroke="#E64135"
        strokeWidth="1.77769"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.19717 4.49836V3.09849C6.19717 2.72722 6.34467 2.37116 6.60722 2.10864C6.86977 1.84611 7.22587 1.69863 7.59717 1.69863H10.3972C10.7685 1.69863 11.1246 1.84611 11.3871 2.10864C11.6497 2.37116 11.7972 2.72722 11.7972 3.09849V4.49836M13.8972 4.49836V14.2974C13.8972 14.6687 13.7497 15.0247 13.4871 15.2873C13.2246 15.5498 12.8685 15.6973 12.4972 15.6973H5.49717C5.12587 15.6973 4.76977 15.5498 4.50722 15.2873C4.24467 15.0247 4.09717 14.6687 4.09717 14.2974V4.49836H13.8972Z"
        stroke="#E64135"
        strokeWidth="1.77769"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default DeleteIcon;
