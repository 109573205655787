import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { toast } from "react-toastify";
import FullPageLoader from "../Common/FullPageLoader";
import ExploreListItem from "./ExploreListItem";
import { SESSION_AUTH } from "../../common/auth";
import { EXPLORE_SERVICES } from "../../services";

const ExploreList = () => {
  const { ref: loadMoreItemsBox, inView: loadMoreItemsBoxVisible } = useInView({
    threshold: 0,
  });
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const dataFetched = useRef(false);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [hasMore, setHasMore] = useState(false);
  const [filter, setFilter] = useState({ page: 1, limit: 18 });

  useEffect(() => {
    if (dataFetched.current) return;
    dataFetched.current = true;
    setLoading(true);
    let parma = {
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
      page: filter?.page,
      limit: filter?.limit,
    };
    EXPLORE_SERVICES.list(parma)
      .then((data) => {
        if (data.code === 200) {
          if (data && data?.data?.length > 0) {
            setListData((old) =>
              parma?.page === 1 ? data.data : [...old, ...data.data]
            );
          }
          setHasMore(data?.data?.length >= filter?.limit);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          toast(data.message);
        }
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => setLoading(false));
  }, [isUserSession, filter, dataFetched, LOGOUT_USER_SESSION]);

  useEffect(() => {
    if (!loadMoreItemsBoxVisible) return;
    dataFetched.current = false;
    setFilter((old) => ({
      ...old,
      page: old.page + 1,
    }));
  }, [loadMoreItemsBoxVisible]);

  const actionCallback = (type, item) => {
    if (!type || !item) return;
    switch (type) {
      case "copy":
        navigator.clipboard.writeText(item?.videoLink);
        toast("Video link copied!");
        break;
      default:
        break;
    }
  };
  return (
    <React.Fragment>
      <div className="videos-layout-list-items">
        {listData.map((listItem, key) => {
          return (
            <ExploreListItem
              actionCallback={actionCallback}
              itemData={listItem}
              key={key}
            />
          );
        })}
      </div>

      {loading && <FullPageLoader normal={true} />}

      {hasMore && (
        <div
          className="videos-layout-list-loadMore"
          ref={loadMoreItemsBox}
        ></div>
      )}
    </React.Fragment>
  );
};

export default ExploreList;
