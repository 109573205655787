import CryptoJS from "crypto-js";
const ENCRYPT_SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export const API_URL = process.env.REACT_APP_ENV
  ? "https://videosbackendapi.6ix.com/api"
  : "http://localhost:3002/api";

const ENCRYPT_DATA = (data) => {
  const encrypted = CryptoJS.AES.encrypt(data, ENCRYPT_SECRET_KEY).toString();
  return encrypted;
};
const DECRYPT_DATA = (data) => {
  try {
    const decrypted = CryptoJS.AES.decrypt(data, ENCRYPT_SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
    return decrypted;
  } catch (error) {
    return;
  }
};

export const SET_PUBLIC_SESSION = (name, value) => {
  if (value && name) {
    let data = ENCRYPT_DATA(value);
    localStorage.setItem(name, data);
  }
};

export const GET_PUBLIC_SESSION = (name) => {
  if (name && localStorage.getItem(name)) {
    try {
      let data = DECRYPT_DATA(localStorage.getItem(name));
      return data;
    } catch (err) {
      return "";
    }
  }
  return;
};
export const REMOVE_PUBLIC_SESSION = (name) => {
  localStorage.removeItem(name);
  return;
};

export const GOOGLE_LANGUAGE_LIST = [
  { name: "Afrikaans", lang: "af" },
  { name: "Albanian", lang: "sq" },
  { name: "Amharic", lang: "am" },
  { name: "Arabic", lang: "ar" },
  { name: "Armenian", lang: "hy" },
  { name: "Assamese", lang: "as" },
  { name: "Aymara", lang: "ay" },
  { name: "Azerbaijani", lang: "az" },
  { name: "Bambara", lang: "bm" },
  { name: "Basque", lang: "eu" },
  { name: "Belarusian", lang: "be" },
  { name: "Bengali", lang: "bn" },
  { name: "Bhojpuri", lang: "bho" },
  { name: "Bosnian", lang: "bs" },
  { name: "Bulgarian", lang: "bg" },
  { name: "Catalan", lang: "ca" },
  { name: "Cebuano", lang: "ceb" },
  //{ name: "Chinese (Simplified)", lang: "zh-CN" },
  { name: "Chinese", lang: "zh-TW", popular: true },
  { name: "Corsican", lang: "co" },
  { name: "Croatian", lang: "hr" },
  { name: "Czech", lang: "cs" },
  { name: "Danish", lang: "da" },
  { name: "Dhivehi", lang: "dv" },
  { name: "Dogri", lang: "doi" },
  { name: "Dutch", lang: "nl" },
  { name: "English", lang: "en", popular: true },
  { name: "Esperanto", lang: "eo" },
  { name: "Estonian", lang: "et" },
  { name: "Ewe", lang: "ee" },
  { name: "Filipino (Tagalog)	", lang: "fil" },
  { name: "Finnish", lang: "fi" },
  { name: "French", lang: "fr", popular: true },
  { name: "Frisian", lang: "fy" },
  { name: "Galician", lang: "gl" },
  { name: "Georgian", lang: "ka" },
  { name: "German", lang: "de", popular: true },
  { name: "Greek", lang: "el" },
  { name: "Guarani", lang: "gn" },
  { name: "Gujarati", lang: "gu" },
  { name: "Haitian Creole", lang: "ht" },
  { name: "Hausa", lang: "ha" },
  { name: "Hawaiian", lang: "haw" },
  { name: "Hebrew", lang: "iw" },
  { name: "Hindi", lang: "hi", popular: true },
  { name: "Hmong", lang: "hmn" },
  { name: "Hungarian", lang: "hu" },
  { name: "Icelandic", lang: "is" },
  { name: "Igbo", lang: "ig" },
  { name: "Ilocano", lang: "ilo" },
  { name: "Indonesian", lang: "id" },
  { name: "Irish", lang: "ga" },
  { name: "Italian", lang: "it" },
  { name: "Japanese", lang: "ja" },
  { name: "Javanese", lang: "jv" },
  { name: "Kannada", lang: "kn" },
  { name: "Kazakh", lang: "kk" },
  { name: "Khmer", lang: "km" },
  { name: "Kinyarwanda", lang: "rw" },
  { name: "Konkani", lang: "gom" },
  { name: "Korean", lang: "ko" },
  { name: "Krio", lang: "kri" },
  { name: "Kurdish", lang: "ku" },
  { name: "Kurdish (Sorani)	", lang: "ckb" },
  { name: "Kyrgyz", lang: "ky" },
  { name: "Lao", lang: "lo" },
  { name: "Latin", lang: "la" },
  { name: "Latvian", lang: "lv" },
  { name: "Lingala", lang: "ln" },
  { name: "Lithuanian", lang: "lt" },
  { name: "Luganda", lang: "lg" },
  { name: "Luxembourgish", lang: "lb" },
  { name: "Macedonian", lang: "mk" },
  { name: "Maithili", lang: "mai" },
  { name: "Malagasy", lang: "mg" },
  { name: "Malay", lang: "ms" },
  { name: "Malayalam", lang: "ml" },
  { name: "Maltese", lang: "mt" },
  { name: "Maori", lang: "mi" },
  { name: "Marathi", lang: "mr" },
  { name: "Meiteilon (Manipuri)	", lang: "mni-Mtei" },
  { name: "Mizo	", lang: "lus" },
  { name: "Mongolian", lang: "mn" },
  { name: "Myanmar (Burmese)", lang: "my" },
  { name: "Nepali", lang: "ne" },
  { name: "Norwegian", lang: "no" },
  { name: "Nyanja (Chichewa)	", lang: "ny" },
  { name: "Odia (Oriya)", lang: "or" },
  { name: "Oromo", lang: "om" },
  { name: "Pashto", lang: "ps" },
  { name: "Persian", lang: "fa" },
  { name: "Polish", lang: "pl" },
  { name: "Portuguese (Portugal, Brazil)", lang: "pt" },
  { name: "Punjabi", lang: "pa" },
  { name: "Quechua", lang: "qu" },
  { name: "Romanian", lang: "ro" },
  { name: "Russian", lang: "ru" },
  { name: "Samoan", lang: "sm" },
  { name: "Sanskrit", lang: "sa" },
  { name: "Scots Gaelic", lang: "gd" },
  { name: "Sepedi", lang: "nso" },
  { name: "Serbian", lang: "sr" },
  { name: "Sesotho", lang: "st" },
  { name: "Shona", lang: "sn" },
  { name: "Sindhi", lang: "sd" },
  { name: "Sinhala (Sinhalese)", lang: "si" },
  { name: "Slovak", lang: "sk" },
  { name: "Slovenian", lang: "sl" },
  { name: "Somali", lang: "so" },
  { name: "Spanish", lang: "es", popular: true },
  { name: "Sundanese", lang: "su" },
  { name: "Swahili", lang: "sw" },
  { name: "Swedish", lang: "sv" },
  { name: "Tagalog (Filipino)	", lang: "tl" },
  { name: "Tajik", lang: "tg" },
  { name: "Tamil", lang: "ta" },
  { name: "Tatar", lang: "tt" },
  { name: "Telugu", lang: "te" },
  { name: "Thai", lang: "th" },
  { name: "Tigrinya", lang: "ti" },
  { name: "Tsonga", lang: "ts" },
  { name: "Turkish", lang: "tr" },
  { name: "Turkmen", lang: "tk" },
  { name: "Twi (Akan)	", lang: "ak" },
  { name: "Ukrainian", lang: "uk" },
  { name: "Urdu", lang: "ur" },
  { name: "Uyghur", lang: "ug" },
  { name: "Uzbek", lang: "uz" },
  { name: "Vietnamese", lang: "vi" },
  { name: "Welsh", lang: "cy" },
  { name: "Xhosa", lang: "xh" },
  { name: "Yiddish", lang: "yi" },
  { name: "Yoruba", lang: "yo" },
  { name: "Zulu", lang: "zu" },
];

export const isValidEmail = (email) => {
  const emailValidRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (email.match(emailValidRegex)) {
    return true;
  }
  return false;
};

export const sizeFormatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const PRIVACY_LIST = {
  private: { name: "Private", value: "private" },
  public: { name: "Public", value: "public" },
};

export const STATUS_LIST = {
  process: { name: "Process", value: "process" },
  active: { name: "Active", value: "active" },
  baned: { name: "Baned", value: "baned" },
  error: { name: "Error", value: "error" },
  delete: { name: "Delete", value: "delete" },
};

export const getThumbnailForVideo = async (videoUrl) => {
  const video = document.createElement("video");
  const canvas = document.createElement("canvas");
  video.style.display = "none";
  canvas.style.display = "none";

  // Trigger video load
  await new Promise((resolve, reject) => {
    video.addEventListener("loadedmetadata", () => {
      video.width = video.videoWidth;
      video.height = video.videoHeight;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // Seek the video to 25%
      video.currentTime = video.duration * 0.25;
    });
    video.addEventListener("seeked", () => resolve());
    video.src = videoUrl;
  });

  // Draw the thumbnailz
  canvas
    .getContext("2d")
    .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  const imageUrl = canvas.toDataURL("image/png");
  return imageUrl;
};
export const VIDEO_TYPE_ALLOW = ["MP4", "MOV", "WEBM"];
export const IMAGE_TYPE_ALLOW = ["PNG", "JPEG", "JPG", "GIF"];
