import React from "react";
import SEO from "../components/Common/SEO";
import Dashboard from "../components/Dashboard";

const DashboardPage = () => {
  return (
    <div className="videos-layout-content-page videos-layout-list">
      <SEO title="Dashboard | 6ix Videos" />
      <Dashboard />
    </div>
  );
};

export default DashboardPage;
