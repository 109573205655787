import React from "react";
export function LinkIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.59961 7.42369C5.80097 7.70324 6.05786 7.93455 6.35288 8.10193C6.64789 8.26931 6.97411 8.36884 7.30942 8.39378C7.64473 8.41872 7.98128 8.36848 8.29625 8.24646C8.61122 8.12445 8.89724 7.93352 9.1349 7.68662L10.5415 6.22588C10.9686 5.76672 11.2049 5.15174 11.1995 4.51341C11.1942 3.87507 10.9476 3.26446 10.513 2.81307C10.0783 2.36168 9.4903 2.10564 8.87562 2.10009C8.26094 2.09455 7.66875 2.33994 7.22659 2.78341L6.42014 3.61603"
        stroke="#8C8E95"
        strokeWidth="1.36"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.69961 6.46783C7.49825 6.21934 7.24135 6.01374 6.94634 5.86495C6.65133 5.71617 6.32511 5.6277 5.9898 5.60553C5.65449 5.58336 5.31793 5.62802 5.00296 5.73648C4.688 5.84493 4.40198 6.01465 4.16431 6.23412L2.7577 7.53255C2.33065 7.94069 2.09436 8.48734 2.0997 9.05475C2.10504 9.62216 2.35159 10.1649 2.78626 10.5662C3.22092 10.9674 3.80891 11.195 4.4236 11.1999C5.03828 11.2048 5.63047 10.9867 6.07262 10.5925L6.87439 9.85242"
        stroke="#8C8E95"
        strokeWidth="1.36"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default LinkIcon;
