import React from "react";
import VideoUploadDrag from "../Uploader/VideoUploadDrag";
import "./style.scss";

const VideoHosting = () => {
  return (
    <div className="videos-layout-content-container videos-layout-home-hosting">
      <h1 className="videos-layout-home-hosting-heading">
        Video hosting <span className="text-primary">made easy</span>
      </h1>
      <div className="videos-layout-home-hosting-text">
        Join thousands of businesses and creators who trust
        <br /> videos.6ix.com to upload and share their videos.
      </div>

      <VideoUploadDrag classes="videos-layout-home-hosting-uploadBox" />
    </div>
  );
};

export default VideoHosting;
