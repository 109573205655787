import { apiCall, apiCallMultipart } from "./servicesCall";

export const VIDEO_SERVICES = {
  upload(data, onUploadProgress) {
    return apiCallMultipart("/video/upload", data, onUploadProgress);
  },
  edit(data) {
    return apiCall("/video/edit", data);
  },
  update(data) {
    return apiCall("/video/update", data);
  },
  list(data) {
    return apiCall("/video/list", data);
  },

  deleteVideo(data) {
    return apiCall("/video/delete", data);
  },
  privacyVideo(data) {
    return apiCall("/video/privacy", data);
  },
  videoDownload(data) {
    return apiCall("/video/download", data);
  },
  replaceVideo(data) {
    return apiCallMultipart("/video/replace", data);
  },
  replaceThumbnail(data) {
    return apiCallMultipart("/video/replace/thumbnail", data);
  },
  getDetail(data) {
    return apiCallMultipart("/video/detail", data);
  },

  embed(data) {
    return apiCallMultipart("/video/embed", data);
  },
  like(data) {
    return apiCall("/video/like", data);
  },
  subscribe(data) {
    return apiCall("/video/subscribe", data);
  },
  static(data) {
    return apiCall("/video/static", data);
  },
};
