import React, { useCallback, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { MdSpeed } from "react-icons/md";
import Plyr from "plyr";
import { useGlobalValue } from "../../GlobalContext";
import { VolumeIcon, ZapIcon } from "../../icons";
import { GOOGLE_LANGUAGE_LIST } from "../../common/constants";
import "./style.scss";
let markers = [
  /* { time: "00:00:08", label: "Welcome to the 6 Live event" }*/
];
const VideosPlayer = (props) => {
  const { videosLang } = useGlobalValue();
  const { videoData } = props;
  const videoRef = useRef(null);
  const speedControlAreaRef = useRef(null);
  const speedControlAreaRef2 = useRef(null);
  const captionListRef = useRef(null);
  const markersListRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isActiveSetting, setIsActiveSetting] = useState(false);
  const [isActiveSetting2, setIsActiveSetting2] = useState(false);
  const [currentSpeed, setCurrentSpeed] = useState(1);
  const [totalVideoTime, setTotalVideoTime] = useState("");
  const [bydefultspeed, setBydefultspeed] = useState(true);
  const [pausespeed, setPausespeed] = useState(false);
  const [langspeed, setlangspeed] = useState(true);

  const [langsubtitle, setLangsubtitle] = useState("en");

  const [isMarkersListVisible, setIsMarkersListVisible] = useState(false);
  const [isCaptionListVisible, setIsCaptionListVisible] = useState(false);
  const [updatedTime, setUpdatedTime] = useState("");
  const [translateReq, setTranslateReq] = useState(null);
  const playerLoaded = useRef(false);
  const [enableCaption, setEnableCaption] = useState(false);

  useEffect(() => {
    setLangsubtitle(videosLang || "en");
    return () => null;
  }, [videosLang]);

  useEffect(() => {
    var originalSubtitle_data = document.querySelector(".plyr__caption");
    var originalSubtitle = "";
    if (originalSubtitle_data) {
      originalSubtitle = originalSubtitle_data.textContent;
      var googleTranslateApiUrl = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=${langsubtitle}&dt=t&q=${encodeURI(
        originalSubtitle
      )}`;

      fetch(googleTranslateApiUrl)
        .then((response) => response.json())
        .then((data) => {
          var translatedSubtitle = data[0][0][0];
          document.querySelector(".plyr__caption").textContent =
            translatedSubtitle;
        })
        .catch((error) => {});
    }
  }, [translateReq, langsubtitle]);

  useEffect(() => {
    if (videoRef?.current?.plyr) {
      videoRef.current.plyr.toggleCaptions(enableCaption);
    }
  }, [enableCaption]);

  const langFunction = (upadteLang) => {
    const playerLanguage = videoRef.current.plyr;

    setEnableCaption(true);
    var number = 132;
    for (var i = 0; i < number; i++) {
      if (upadteLang === i) {
        playerLanguage.currentTrack = i;
        return;
      }
    }
  };

  const setSpeed = useCallback((speed) => {
    if (videoRef.current) {
      videoRef.current.playbackRate = speed;
    }
  }, []);

  // marker time convert in seconds
  const convertHMSToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    return (
      parseInt(hours, 10) * 3600 +
      parseInt(minutes, 10) * 60 +
      parseInt(seconds, 10)
    );
  };

  const handleMarkerClick = useCallback(
    (time) => {
      // Convert the time to seconds
      const [hours, minutes, seconds] = time.split(":");
      const timeInSeconds =
        parseInt(hours, 10) * 3600 +
        parseInt(minutes, 10) * 60 +
        parseInt(seconds, 10);

      // Update the video current time
      if (videoRef.current) {
        videoRef.current.currentTime = timeInSeconds;
      }
    },
    [videoRef]
  );

  const toggleMarkersList = useCallback(() => {
    setIsMarkersListVisible((current) => !current);
  }, []);

  const calculateUpdatedTime = (speed) => {
    if (videoRef.current) {
      const duration = videoRef.current.duration;
      const updatedDuration = duration / speed;

      const hours = Math.floor(updatedDuration / 3600);
      const minutes = Math.floor((updatedDuration % 3600) / 60);
      const seconds = Math.floor(updatedDuration % 60);

      setUpdatedTime(
        `${hours} hr ${String(minutes).padStart(2, "0")} min ${String(
          seconds
        ).padStart(2, "0")} sec`
      );
    }
  };

  const handleXBtnClick = (speed, label) => {
    if (label === "click" && pausespeed) {
      setSpeed(speed);
    }
    if (label === "click") {
      setCurrentSpeed(speed);
    }
    if (speed === 1) {
      setBydefultspeed(true);
    } else {
      setBydefultspeed(false);
    }

    calculateUpdatedTime(speed);
  };

  const leavehover = () => {
    calculateUpdatedTime(currentSpeed);
    setBydefultspeed(true);
  };

  useEffect(() => {
    if (!playerLoaded.current) {
      playerLoaded.current = true;
      const player = new Plyr(videoRef.current, {
        title: videoData.title,
        controls: [
          "play-large",
          "play",
          "current-time",
          "progress",
          "mute",
          "volume",
          "pip",
          "fullscreen",
        ],
        muted: isMuted,
        autoplay: true,
        markers: {
          enabled: false,
          points: markers.map((marker) => ({
            time: convertHMSToSeconds(marker.time),
            label: marker.label,
          })),
        },
        keyboard: { global: false },
        captions: {
          active: enableCaption,
          language: "en",
          update: true,
        },
        invertTime: false,
        hideControls: false,
      });

      player.captions.active = enableCaption;

      if (pausespeed && langspeed) {
        player.play();
        handleMarkerClick("00:00:00");
      }

      player.on("cuechange", () => {
        console.log(new Date(), "cue");
        if (videoRef.current?.plyr?.captions?.active) {
          setTranslateReq(new Date());
        }
      });

      const customButton = document.createElement("button");
      const settingImg = `<svg x="0px" y="0px" viewBox="0 0 40 34" enableBackground="new 0 0 40 34" aria-hidden="true" style="fill: rgb(255, 255, 255); height: 100%; left: 0px; strokeWidth: 0px; top: 0px; width: 100%;"><g><g><path d="M28.3,16.4h-1.9c-0.4,0-0.8-0.3-0.9-0.7l-0.4-1.1c-0.2-0.3-0.1-0.8,0.2-1.1l1.3-1.3c0.3-0.3,0.3-0.7,0-1l-0.4-0.4c-0.3-0.3-0.7-0.3-1,0l-1.3,1.3c-0.3,0.3-0.8,0.3-1.1,0.1l-1.1-0.5c-0.4-0.1-0.7-0.5-0.7-0.9V9.1c0-0.4-0.3-0.7-0.7-0.7h-0.6c-0.4,0-0.7,0.3-0.7,0.7v1.7c0,0.4-0.3,0.8-0.7,0.9l-1.2,0.5c-0.3,0.2-0.8,0.1-1.1-0.2l-1.2-1.2c-0.3-0.3-0.7-0.3-1,0l-0.4,0.4c-0.3,0.3-0.3,0.7,0,1l1.2,1.2c0.3,0.3,0.3,0.8,0.1,1.1l-0.5,1.2c-0.1,0.4-0.5,0.7-0.9,0.7h-1.6c-0.4,0-0.7,0.3-0.7,0.7v0.6c0,0.4,0.3,0.7,0.7,0.7h1.6c0.4,0,0.8,0.3,0.9,0.7l0.5,1.2c0.2,0.3,0.1,0.8-0.1,1.1l-1.2,1.2c-0.3,0.3-0.3,0.7,0,1l0.4,0.4c0.3,0.3,0.7,0.3,1,0l1.2-1.2c0.3-0.3,0.8-0.3,1.1-0.2l1.2,0.5c0.4,0.1,0.7,0.5,0.7,0.9v1.7c0,0.4,0.3,0.7,0.7,0.7h0.6c0.4,0,0.7-0.3,0.7-0.7V24c0-0.4,0.3-0.8,0.7-0.9l1.1-0.5c0.3-0.2,0.8-0.1,1.1,0.1l1.3,1.3c0.3,0.3,0.7,0.3,1,0l0.4-0.4c0.3-0.3,0.3-0.7,0-1l-1.3-1.3C25,21,25,20.5,25.1,20.2l0.4-1.1c0.1-0.4,0.5-0.7,0.9-0.7h1.9c0.4,0,0.7-0.3,0.7-0.7v-0.6C29,16.7,28.7,16.4,28.3,16.4z M23.8,17.5c0,2.2-1.8,3.9-3.9,3.9c-2.2,0-3.9-1.8-3.9-3.9s1.7-3.9,3.9-3.9C22.1,13.6,23.8,15.3,23.8,17.5z"></path></g></g></svg>`;
      customButton.className = "plyr__control plyr__controls__item";
      customButton.id = "customSettingBtn";
      customButton.innerHTML = settingImg;

      const customButtonList = document.createElement("button");
      const listImg = `<svg x="0px" y="0px" viewBox="0 0 40 34" enableBackground="new 0 0 40 34" aria-hidden="true" style="fill: rgb(255, 255, 255); height: 100%; left: 0px; strokeWidth: 0px; top: 0px; width: 100%;"><g><g><circle cx="10.6" cy="10.1" r="1.6"></circle><path d="M29.5,10.1c0,0.6-0.5,1.1-1.1,1.1H15.7c-0.6,0-1.1-0.5-1.1-1.1l0,0c0-0.6,0.5-1.1,1.1-1.1h12.7C29,9.1,29.5,9.5,29.5,10.1L29.5,10.1z"></path></g><g><circle cx="10.6" cy="16.8" r="1.6"></circle><path d="M29.5,16.8c0,0.6-0.5,1.1-1.1,1.1H15.7c-0.6,0-1.1-0.5-1.1-1.1l0,0c0-0.6,0.5-1.1,1.1-1.1h12.7C29,15.7,29.5,16.2,29.5,16.8L29.5,16.8z"></path></g><g><circle cx="10.6" cy="23.4" r="1.6"></circle><path d="M29.5,23.4c0,0.6-0.5,1.1-1.1,1.1H15.7c-0.6,0-1.1-0.5-1.1-1.1l0,0c0-0.6,0.5-1.1,1.1-1.1h12.7C29,22.4,29.5,22.9,29.5,23.4L29.5,23.4z"></path></g></g></svg>`;
      customButtonList.className = "plyr__control plyr__controls__item";
      customButtonList.id = "customListBtn";
      customButtonList.innerHTML = listImg;

      const customButtonCaption = document.createElement("button");
      const captionImg = `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32"><defs><image  width="30" height="22" id="img1" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAWCAMAAADgvdz9AAAAAXNSR0IB2cksfwAAAG9QTFRFAAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////L9MC0QAAACV0Uk5TADSNqjNz/v9yjqn8e1Vh3t18uWVkuukoDbGvKerPrdAC0f1xjM7nk6AAAACZSURBVHicrZLLEoIwDEVjW7kIAooKKGgR+f9vJFofxaFlw9ncxZk8ZhIiWgk5iVDErAM4CEKudVr2ijZuCwiKfDoi6dNyCR1vkw9pBuzSZB9bOqcfB+DIcbJ0UX5tdQYuFdXNYqvhKuWtMGnQttYtTy3f+aLV/7oe6bvdXHPzxuS4+cxJZg7qe4eO3yl0+u7xXFL106/Yc+0A3CkS1czLxo8AAAAASUVORK5CYII="/></defs><style></style><use id="Background" href="#img1" x="1" y="5"/></svg>`;
      customButtonCaption.className = "plyr__control plyr__controls__item";
      customButtonCaption.id = "customCaptionBtn";
      customButtonCaption.innerHTML = captionImg;

      player.on("loadeddata", () => {
        setIsActiveSetting2(true);
        const controls = player.elements.controls;
        if (controls) {
          // ------
          if (speedControlAreaRef.current) {
            const speedControlContainer = document.createElement("div");
            speedControlContainer.appendChild(speedControlAreaRef.current);
            controls.insertBefore(speedControlContainer, controls.children[5]);
            controls.insertBefore(customButton, controls.children[4]);
          }
          // ----
          if (captionListRef?.current) {
            const captionListContainer = document.createElement("div");
            captionListContainer.appendChild(captionListRef.current);
            controls.insertBefore(captionListContainer, controls.children[6]);
            controls.insertBefore(customButtonCaption, controls.children[6]);
          }

          if (markersListRef.current) {
            const markersListContainer = document.createElement("div");
            markersListContainer.appendChild(markersListRef.current);

            controls.insertBefore(markersListContainer, controls.children[7]);
            controls.insertBefore(customButtonList, controls.children[5]);
          }
        }

        // Set total video time
        const duration = player.duration;
        const totalHours = Math.floor(duration / 3600);
        const totalMinutes = Math.floor((duration % 3600) / 60);
        const totalSeconds = Math.floor(duration % 60);

        setTotalVideoTime(
          `${totalHours} hr ${String(totalMinutes).padStart(2, "0")} min ${String(
            totalSeconds
          ).padStart(2, "0")} sec`
        );
        setUpdatedTime(
          `${totalHours} hr ${String(totalMinutes).padStart(2, "0")} min ${String(
            totalSeconds
          ).padStart(2, "0")} sec`
        );

        // Set video speed to 1x on page refresh
        setSpeed(1);
        setCurrentSpeed(1);
      });

      customButton.addEventListener("click", (event) => {
        event.stopPropagation();
        setIsActiveSetting((current) => !current);
        setIsMarkersListVisible(false);
        setIsCaptionListVisible(false);
        setIsActiveSetting2(false);
      });

      customButtonList.addEventListener("click", (event) => {
        event.stopPropagation();
        toggleMarkersList();

        setIsActiveSetting(false);
        setIsCaptionListVisible(false);
        setIsActiveSetting2(false);
      });
      customButtonCaption.addEventListener("click", (event) => {
        event.stopPropagation();
        setIsCaptionListVisible((current) => !current);
        setIsActiveSetting(false);
        setIsMarkersListVisible(false);
        setIsActiveSetting2(false);
      });

      const customsound = document.querySelector(".plyr__volume");
      if (customsound) {
        customsound.addEventListener("click", (event) => {
          setIsMuted(false);
        });
      }
      player.on("playing", () => {});
    }
    // Event listener to close speed-control-area when clicking outside of it
    const handleClickOutside = (event) => {
      if (
        speedControlAreaRef.current &&
        !speedControlAreaRef.current.contains(event.target)
      ) {
        setIsActiveSetting(false);
        setIsMarkersListVisible(false);
        setIsCaptionListVisible(false);
      }
    };

    // Attach the event listeners to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Remove the event listeners when the component unmounts
      document.removeEventListener("click", handleClickOutside);
    };

    // eslint-disable-next-line
  }, [playerLoaded]);

  useEffect(() => {
    const controlsElement = document.querySelector(".plyr__controls");
    const playBtnElement = document.querySelector(".plyr__control--overlaid");
    if (controlsElement) {
      controlsElement.classList.add("hidden");
    }
    if (playBtnElement) {
      playBtnElement.classList.add("visible");
    }
  }, []);

  const controlButton = document.querySelector(
    "button.plyr__control--overlaid"
  );
  const controlsElement = document.querySelector(".plyr__controls");
  const sppedAnimatedElement = document.querySelector(
    ".speed-control-area-animated"
  );
  if (controlButton) {
    controlButton.addEventListener("click", () => {
      setPausespeed(true);
      setSpeed(currentSpeed);
      setIsMuted(false);

      if (controlsElement) {
        controlsElement.classList.remove("hidden");
      }
      controlButton.classList.remove("visible");
      sppedAnimatedElement.classList.remove("visible");
      controlButton.style.display = "none";
    });
  }

  const clickforsound = document.querySelector(".click-for-sound");
  if (clickforsound) {
    clickforsound.addEventListener("click", (event) => {
      setPausespeed(true);
      setSpeed(currentSpeed);
      setIsMuted(false);

      const controlsElement = document.querySelector(".plyr__controls");

      const plyrBtnArea = document.querySelector(".plyr__control--overlaid");
      const animatedArea = document.querySelector(
        ".speed-control-area-animated"
      );

      plyrBtnArea.classList.remove("visible");
      controlsElement.classList.remove("hidden");

      animatedArea.classList.remove("visible");
    });
  }

  useEffect(() => {
    if (videoRef?.current?.plyr) {
      videoRef.current.plyr.muted = isMuted;
    }
  }, [isMuted]);
  return (
    <div className="videos-player video-wrap-body" translate="no">
      <div className="video-wrap">
        <video
          ref={videoRef}
          id="player"
          muted
          playsInline
          preload="true"
          data-poster={videoData?.thumb}
          data-plyr-config={{ title: videoData?.title, muted: true }}
          crossOrigin="anonymous"
        >
          <source src={videoData?.video} type="video/mp4" />

          {videoData?.caption &&
            GOOGLE_LANGUAGE_LIST.map((result, key) => {
              return (
                <track
                  key={key}
                  kind="captions"
                  label={result?.name}
                  srcLang={result?.lang}
                  src={videoData?.caption}
                />
              );
            })}
        </video>
        {isMuted && (
          <div className="click-for-sound">
            <button
              type="button"
              className="click-for-sound-btn"
              id="clickForSound"
            >
              <span className="click-for-sound-text">Click for sound</span>{" "}
              <VolumeIcon />
            </button>
          </div>
        )}
        <div
          ref={speedControlAreaRef2}
          className={`speed-control-area speed-control-area-animated ${
            isActiveSetting2 ? "visible" : ""
          }`}
          id="speedControlArea2"
        >
          <div className="speed-control-area-num speed-control-area-row">
            <IconContext.Provider value={{ className: "speed-icon" }}>
              <MdSpeed />
            </IconContext.Provider>
            <button
              className={`x-btn ${
                currentSpeed === 0.8 ? "light-text active" : ""
              }`}
              onClick={() => handleXBtnClick(0.8, "click")}
              onMouseEnter={() => handleXBtnClick(0.8, "hover")}
              onMouseLeave={() => leavehover()}
            >
              0.8x
            </button>
            <button
              className={`x-btn ${
                currentSpeed === 1 ? "light-text active" : ""
              }`}
              onClick={() => handleXBtnClick(1, "click")}
              onMouseEnter={() => handleXBtnClick(1, "hover")}
              onMouseLeave={() => leavehover()}
            >
              1x
            </button>
            <button
              className={`x-btn ${
                currentSpeed === 1.2 ? "light-text active" : ""
              }`}
              onClick={() => handleXBtnClick(1.2, "click")}
              onMouseEnter={() => handleXBtnClick(1.2, "hover")}
              onMouseLeave={() => leavehover()}
            >
              1.2x
            </button>
            <button
              className={`x-btn ${
                currentSpeed === 1.5 ? "light-text active" : ""
              }`}
              onClick={() => handleXBtnClick(1.5, "click")}
              onMouseEnter={() => handleXBtnClick(1.5, "hover")}
              onMouseLeave={() => leavehover()}
            >
              1.5x
            </button>
            <button
              className={`x-btn ${
                currentSpeed === 1.7 ? "light-text active" : ""
              }`}
              onClick={() => handleXBtnClick(1.7, "click")}
              onMouseEnter={() => handleXBtnClick(1.7, "hover")}
              onMouseLeave={() => leavehover()}
            >
              1.7x
            </button>
            <button
              className={`x-btn ${
                currentSpeed === 2 ? "light-text active" : ""
              }`}
              onClick={() => handleXBtnClick(2, "click")}
              onMouseEnter={() => handleXBtnClick(2, "hover")}
              onMouseLeave={() => leavehover()}
            >
              2x
            </button>
            <button
              className={`x-btn ${
                currentSpeed === 2.5 ? "light-text active" : ""
              }`}
              onClick={() => handleXBtnClick(2.5, "click")}
              onMouseEnter={() => handleXBtnClick(2.5, "hover")}
              onMouseLeave={() => leavehover()}
            >
              2.5x
            </button>
          </div>
          <div className="speed-control-area-time speed-control-area-row">
            {bydefultspeed ? (
              ""
            ) : (
              <>
                <span>
                  <del>{totalVideoTime}</del>
                </span>
                <ZapIcon />
              </>
            )}

            <span
              className={`vid-light-text ${
                updatedTime !== totalVideoTime ? "updated" : ""
              }`}
            >
              {updatedTime}
            </span>
          </div>
        </div>
        <div
          ref={speedControlAreaRef}
          className={`speed-control-area ${isActiveSetting ? "visible" : ""}`}
          id="speedControlArea"
        >
          <div className="speed-control-area-num speed-control-area-row">
            <button
              className={`x-btn ${
                currentSpeed === 0.8 ? "light-text active" : ""
              }`}
              onClick={() => handleXBtnClick(0.8, "click")}
            >
              0.8x
            </button>
            <button
              className={`x-btn ${
                currentSpeed === 1 ? "light-text active" : ""
              }`}
              onClick={() => handleXBtnClick(1, "click")}
            >
              1x
            </button>
            <button
              className={`x-btn ${
                currentSpeed === 1.2 ? "light-text active" : ""
              }`}
              onClick={() => handleXBtnClick(1.2, "click")}
            >
              1.2x
            </button>
            <button
              className={`x-btn ${
                currentSpeed === 1.5 ? "light-text active" : ""
              }`}
              onClick={() => handleXBtnClick(1.5, "click")}
            >
              1.5x
            </button>
            <button
              className={`x-btn ${
                currentSpeed === 1.7 ? "light-text active" : ""
              }`}
              onClick={() => handleXBtnClick(1.7, "click")}
            >
              1.7x
            </button>
            <button
              className={`x-btn ${
                currentSpeed === 2 ? "light-text active" : ""
              }`}
              onClick={() => handleXBtnClick(2, "click")}
            >
              2x
            </button>
            <button
              className={`x-btn ${
                currentSpeed === 2.5 ? "light-text active" : ""
              }`}
              onClick={() => handleXBtnClick(2.5, "click")}
            >
              2.5x
            </button>
          </div>
          <div className="speed-control-area-time speed-control-area-row">
            <span>
              <del>{totalVideoTime}</del>
            </span>
            <ZapIcon />
            <span className="vid-light-text">{updatedTime}</span>
          </div>
        </div>

        {videoData?.caption && (
          <div
            ref={captionListRef}
            className={`videos-player-caption-list ${isCaptionListVisible ? "visible" : ""}`}
          >
            <div
              //              onClick={() => langFunction("disabled")}
              onClick={() => setEnableCaption(!enableCaption)}
              className="videos-player-caption-list-label"
            >
              <span className="videos-player-caption-list-label-text">
                {enableCaption ? <>Disable</> : <>Enable</>}
              </span>
            </div>

            <div className="videos-player-caption-list-languages">
              {GOOGLE_LANGUAGE_LIST.map((result, key) => {
                return (
                  <div
                    onClick={() => {
                      setLangsubtitle(result?.lang);
                      setlangspeed(false);
                      langFunction(key);
                    }}
                    className={`videos-player-caption-list-label ${langsubtitle === result?.lang ? "active" : ""}`}
                    key={key}
                  >
                    <span className="videos-player-caption-list-label-text">
                      {result?.name}
                    </span>{" "}
                    <span className="videos-player-caption-list-label-short">
                      {result?.lang}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {markers && markers?.length > 0 && (
          <div
            ref={markersListRef}
            className={`markers-list ${isMarkersListVisible ? "visible" : ""}`}
          >
            <ul>
              {markers.map((marker, index) => (
                <li key={index} onClick={() => handleMarkerClick(marker.time)}>
                  <span className="markers-list-time">{marker.time} </span>
                  <span className="markers-list-text"> {marker.label}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideosPlayer;
