import React from "react";
export function DownloadIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1998 12.0863V14.8291C16.1998 15.1928 16.0312 15.5417 15.7312 15.7989C15.4311 16.0561 15.0242 16.2005 14.5998 16.2005H3.39981C2.97546 16.2005 2.56849 16.0561 2.26843 15.7989C1.96838 15.5417 1.7998 15.1928 1.7998 14.8291V12.0863"
        stroke="#C0C0C0"
        strokeWidth="1.90857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.88623 7.9725L9.00052 12.0868L13.1148 7.9725"
        stroke="#C0C0C0"
        strokeWidth="1.90857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12.0857V1.79999"
        stroke="#C0C0C0"
        strokeWidth="1.90857"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default DownloadIcon;
