import React from "react";
import Videos from "../components/Videos";

const VideosPage = () => {
  return (
    <div className="videos-layout-content-page videos-layout-video videos-layout-content-page-nospace">
      <Videos />
    </div>
  );
};

export default VideosPage;
