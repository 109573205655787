import React from "react";
export function ClockIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_481_13762)">
        <path
          d="M8.0001 14.4C11.5347 14.4 14.4001 11.5346 14.4001 7.99998C14.4001 4.46535 11.5347 1.59998 8.0001 1.59998C4.46548 1.59998 1.6001 4.46535 1.6001 7.99998C1.6001 11.5346 4.46548 14.4 8.0001 14.4Z"
          stroke="#C0C0C0"
          strokeWidth="1.52686"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.57324 4.15991V7.99991L10.1332 9.27991"
          stroke="#C0C0C0"
          strokeWidth="1.52686"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_481_13762">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default ClockIcon;
