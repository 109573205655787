import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export function ConfirmModal(props) {
  const {
    icon,
    request,
    cancel,
    accept,
    title,
    message,
    cancelLabel,
    yesLabel,
  } = props;
  return (
    <Dialog
      open={request}
      onClose={cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="videos-dialog"
    >
      {icon && <div className="videos-dialog-icon">{icon}</div>}
      <DialogTitle className="text-center">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          className="text-center"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </DialogContent>
      <DialogActions className="videos-dialog-action">
        <Button
          variant="outlined"
          onClick={cancel}
          className="videos-dialog-btn  videos-btn videos-btn-secondary"
        >
          {cancelLabel || "Cancel"}
        </Button>
        <Button
          variant="outlined"
          onClick={accept}
          className="videos-dialog-btn videos-btn videos-btn-primary"
        >
          {yesLabel || "Yes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
