import React from "react";
export function UploadIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 11.75V14.75C16.25 15.1478 16.092 15.5294 15.8107 15.8107C15.5294 16.092 15.1478 16.25 14.75 16.25H4.25C3.85218 16.25 3.47064 16.092 3.18934 15.8107C2.90804 15.5294 2.75 15.1478 2.75 14.75V11.75"
        stroke="#E9E9E9"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.25 6.5L9.5 2.75L5.75 6.5"
        stroke="#E9E9E9"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 2.75V11.75"
        stroke="#E9E9E9"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default UploadIcon;
