import React from "react";
export function EmbedIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_333_3434)">
        <path
          d="M13.2895 4.47008L13.2894 4.46989C13.2232 4.39972 13.1128 4.39626 13.0422 4.46274C12.9718 4.52921 12.9688 4.64002 13.035 4.71013L13.0351 4.71021L16.7604 8.65673L17.0844 8.99993L16.7604 9.34315L13.0351 13.2901L13.035 13.2902C12.9689 13.3602 12.9717 13.4709 13.0423 13.5377C13.0763 13.5696 13.1186 13.5852 13.1622 13.5852C13.2098 13.5852 13.2555 13.5666 13.2897 13.5306C13.2897 13.5305 13.2898 13.5305 13.2898 13.5304L17.4519 9.12066L13.2895 4.47008ZM13.2895 4.47008L17.4521 8.88009M13.2895 4.47008L17.4521 8.88009M17.4521 8.88009C17.5156 8.94738 17.5158 9.0527 17.4521 9.12047L17.4521 8.88009Z"
          fill="#C0C0C0"
          stroke="#C0C0C0"
        />
        <path
          d="M0.91586 9.00032L1.23978 8.65713L4.96485 4.71044C5.0312 4.64012 5.02793 4.5293 4.95771 4.46306L4.95755 4.4629C4.88755 4.3968 4.77662 4.39988 4.71031 4.47017L4.71023 4.47025L0.547726 8.88025C0.547719 8.88026 0.547713 8.88026 0.547706 8.88027C0.484118 8.94767 0.484078 9.05318 0.547706 9.12066L0.91586 9.00032ZM0.91586 9.00032L1.23978 9.34352L4.96485 13.2902C4.96486 13.2903 4.96487 13.2903 4.96489 13.2903C5.03139 13.3608 5.02792 13.4714 4.95785 13.5375L4.95743 13.5379C4.92355 13.5699 4.88109 13.5856 4.83779 13.5856C4.7901 13.5856 4.74471 13.5672 4.71044 13.5309C4.71043 13.5309 4.71043 13.5309 4.71042 13.5309L0.547749 9.12071L0.91586 9.00032Z"
          fill="#C0C0C0"
          stroke="#C0C0C0"
        />
        <path
          d="M10.0921 1.85172C9.72376 1.79593 9.37905 2.04881 9.3228 2.41714L7.34278 15.3772C7.28653 15.7458 7.53966 16.0903 7.90821 16.1465C7.94287 16.1517 7.97704 16.1542 8.01104 16.1542C8.33887 16.1542 8.62642 15.915 8.6775 15.5811L10.6575 2.62102C10.7138 2.25244 10.4606 1.90797 10.0921 1.85172Z"
          fill="#C0C0C0"
        />
      </g>
      <defs>
        <clipPath id="clip0_333_3434">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default EmbedIcon;
