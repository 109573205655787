import React from "react";
export function ReplaceIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1159 4.39579H3.88428"
        stroke="#C0C0C0"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.88414 13.6042H14.1157"
        stroke="#C0C0C0"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.38007 7.89158L3.88428 4.39579L7.38007 0.899994"
        stroke="#C0C0C0"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6199 17.1L14.1157 13.6042L10.6199 10.1084"
        stroke="#C0C0C0"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default ReplaceIcon;
