import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGlobalValue } from "../../GlobalContext";
import { MenuIcon, UserLogin } from "../../icons";
import { LoginPopup } from "../Account/Login/LoginPopup";
import HeaderAccounts from "./HeaderAccounts";
import HeaderApps from "./HeaderApps";
import HeaderLanguage from "./HeaderLanguage";
import HeaderLogo from "./HeaderLogo";
import HeaderSidebar from "./HeaderSidebar";
import HeaderUsers from "./HeaderUser";
import { SESSION_AUTH } from "../../common/auth";
import { USER_SERVICES } from "../../services";
import "./style.scss";

const Header = () => {
  const { loginRequest, setLoginRequest } = useGlobalValue();
  const { isUserSession, LOGOUT_USER_SESSION, UPDATE_USER_SESSION } =
    SESSION_AUTH();
  const [userData, setUserData] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      //initializeGSI();
    }
    return () => null;
  }, [isUserSession]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    USER_SERVICES.getUser({
      userId: userData?.userId,
      org: userData?.org?.id,
    })
      .then((data) => {
        if (data.code === 200) {
          UPDATE_USER_SESSION(data?.data);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          toast(data.message);
        }
      })
      .catch((error) => {
        toast(error.message);
      });
  }, [dataFetched, userData, LOGOUT_USER_SESSION, UPDATE_USER_SESSION]);

  return (
    <>
      <div className="videos-layout-header">
        <div className="videos-layout-header-inner">
          <div className="videos-layout-header-left">
            <div
              className="videos-layout-header-menuButton"
              onClick={() => setOpenSidebar(!openSidebar)}
            >
              <MenuIcon close={openSidebar} />
            </div>
            <div className="videos-layout-header-lineV"></div>
            <HeaderLogo />
            <HeaderApps />
          </div>
          <div className="videos-layout-header-right">
            {userData?.org && (
              <>
                <div className="videos-layout-header-right-item">
                  <HeaderAccounts />
                </div>
                <div className="videos-layout-header-lineV"></div>
              </>
            )}

            <div className="videos-layout-header-right-item">
              <HeaderLanguage />
            </div>

            <div className="videos-layout-header-right-item">
              {userData ? (
                <HeaderUsers />
              ) : (
                <div
                  className="videos-layout-header-right-item-btn"
                  onClick={() => {
                    setLoginRequest(true);
                  }}
                >
                  <UserLogin />
                  <div className="videos-layout-header-right-item-btn-text">
                    LOGIN
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {openSidebar && <HeaderSidebar setOpenSidebar={setOpenSidebar} />}

      {loginRequest && !userData ? (
        <LoginPopup
          dropOut={true}
          cancel={true}
          handleLogin={() => {
            setLoginRequest(false);
          }}
        />
      ) : null}
    </>
  );
};

export default Header;
