import React from "react";
import VideoStatic from "./VideoStatic";
const VideosDetail = (props) => {
  const { videoData } = props;
  return (
    <React.Fragment>
      <div className="videos-layout-video-detail">
        <h1 className="videos-layout-video-detail-title">{videoData?.title}</h1>
        <VideoStatic videoData={videoData} />
        <div className="videos-layout-video-detail-desc">
          {videoData?.description}
        </div>
      </div>
    </React.Fragment>
  );
};

export default VideosDetail;
