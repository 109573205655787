import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const EmbedModal = (props) => {
  const { cancel, embedUrl, title } = props;
  const [embedText, setEmbedtext] = useState("");

  useEffect(() => {
    setEmbedtext(
      `<iframe width="560" height="315" src="${embedUrl}" title="${title} | 6ix Videos" frameborder="0" autoplay allowfullscreen muted></iframe>`
    );
  }, [embedUrl, title]);

  const copyEmbed = () => {
    navigator.clipboard.writeText(embedText);
    toast("Embed code copied!");
    cancel();
  };

  return (
    <Dialog
      fullWidth
      open={true}
      onClose={cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="videos-dialog"
      maxWidth="xs"
    >
      <DialogTitle className="text-center">
        Embed <br /> <small>{title}</small>
      </DialogTitle>
      <DialogContent className="text-center mt-20 mb-20">
        <div className="videos-embedBox mb-20">{embedText}</div>
      </DialogContent>
      <DialogActions className="videos-dialog-action">
        <Button
          variant="outlined"
          className="videos-btn  videos-btn-primary"
          onClick={copyEmbed}
        >
          Copy
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmbedModal;
