import React, { useEffect, useState } from "react";
import { List, ListItemButton, ListItemText, Menu } from "@mui/material";
import { Exit, UserIcon } from "../../icons";
import { SESSION_AUTH } from "../../common/auth";

const HeaderUsers = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [userData, setUserData] = useState(null);

  const [headerMenuEl, setHeaderMenuEl] = useState(null);
  const headerMenuOpen = Boolean(headerMenuEl);
  const headerMenuOpenReq = (event) => {
    setHeaderMenuEl(event.currentTarget);
  };
  const headerMenuClose = () => {
    setHeaderMenuEl(null);
  };

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
    }
    return () => null;
  }, [isUserSession]);
  const handleLogout = () => {
    LOGOUT_USER_SESSION(isUserSession);
    headerMenuClose();
  };
  if (!isUserSession) {
    return;
  }
  return (
    <>
      <div
        className={`videos-layout-header-right-item-btn ${
          headerMenuOpen ? "active" : ""
        }`}
        id="header-users-menu-button"
        aria-controls={headerMenuOpen ? "header-users-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={headerMenuOpen ? "true" : undefined}
        onClick={headerMenuOpenReq}
      >
        <div className="videos-layout-header-right-item-btn-user">
          {userData?.profileImage ? (
            <img src={userData.profileImage} alt="6ix user" />
          ) : (
            <UserIcon />
          )}
        </div>
      </div>

      <Menu
        id="header-users-menu"
        anchorEl={headerMenuEl}
        open={headerMenuOpen}
        onClose={headerMenuClose}
        MenuListProps={{
          "aria-labelledby": "header-users-menu-button",
        }}
        className="videos-layout-header-right-item-dropdown videos-layout-header-right-item-dropdown-users"
      >
        <List
          component="nav"
          className="videos-layout-header-right-item-dropdown-users-list"
        >
          <ListItemButton
            className="videos-layout-header-right-item-dropdown-users-list-item"
            component="a"
            href="https://events.6ix.com/profile"
            target="_blank"
            onClick={() => {
              headerMenuClose();
            }}
          >
            <ListItemText
              className="videos-layout-header-right-item-dropdown-users-list-item-text"
              primary="Manage Profile"
            />
          </ListItemButton>

          <ListItemButton
            className="videos-layout-header-right-item-dropdown-users-list-item"
            component="a"
            href="https://events.6ix.com/account/setting"
            target="_blank"
            onClick={() => {
              headerMenuClose();
            }}
          >
            <ListItemText
              className="videos-layout-header-right-item-dropdown-users-list-item-text"
              primary="Account Settings"
            />
          </ListItemButton>

          <div className="videos-layout-header-right-item-dropdown-users-list-divider"></div>
          <ListItemButton
            className="videos-layout-header-right-item-dropdown-users-list-item"
            component="a"
            onClick={handleLogout}
          >
            <Exit />
            <ListItemText
              className="videos-layout-header-right-item-dropdown-users-list-item-text"
              primary="Log Out"
            />
          </ListItemButton>
        </List>
      </Menu>
    </>
  );
};

export default HeaderUsers;
