import React from "react";
import SEO from "../components/Common/SEO";
import Explore from "../components/Explore";

const ExplorePage = () => {
  return (
    <div className="videos-layout-content-page videos-layout-explore">
      <SEO title="Explore | 6ix Videos" />
      <Explore />
    </div>
  );
};

export default ExplorePage;
