import React from "react";
export function MoreIcon() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3996 8.99999C10.3996 8.50294 9.99666 8.09999 9.49961 8.09999C9.00255 8.09999 8.59961 8.50294 8.59961 8.99999C8.59961 9.49705 9.00255 9.89999 9.49961 9.89999C9.99667 9.89999 10.3996 9.49705 10.3996 8.99999Z"
        stroke="#C0C0C0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.0998 8.99999C4.0998 8.50294 3.69686 8.09999 3.1998 8.09999C2.70275 8.09999 2.2998 8.50294 2.2998 8.99999C2.2998 9.49705 2.70275 9.89999 3.1998 9.89999C3.69686 9.89999 4.0998 9.49705 4.0998 8.99999Z"
        stroke="#C0C0C0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7004 8.99999C16.7004 8.50294 16.2974 8.09999 15.8004 8.09999C15.3033 8.09999 14.9004 8.50294 14.9004 8.99999C14.9004 9.49705 15.3033 9.89999 15.8004 9.89999C16.2974 9.89999 16.7004 9.49705 16.7004 8.99999Z"
        stroke="#C0C0C0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default MoreIcon;
