import React from "react";
import SEO from "../components/Common/SEO";
import VideoHosting from "../components/Home/VideoHosting";

const HomePage = () => {
  return (
    <div className="videos-layout-content-page videos-layout-home">
      <SEO title="Home | 6ix Videos" />
      <VideoHosting />
    </div>
  );
};

export default HomePage;
