import React from "react";
import { Link } from "react-router-dom";
import { Box, ButtonGroup } from "@mui/material";
import { sizeFormatBytes } from "../../common/constants";

const VideoProcessItem = (props) => {
  const { title, process, size, complete } = props;

  return (
    <Box className="videos-layout-list-items-box">
      <div className="videos-layout-list-items-box-inner">
        <Link className="videos-layout-list-items-box-media" title={title}>
          {complete ? (
            <div className="videos-layout-list-items-box-media-process">
              <div className="videos-layout-list-items-box-media-process-text">
                Publish Soon..
              </div>
            </div>
          ) : (
            <div className="videos-layout-list-items-box-media-process">
              <div className="videos-layout-list-items-box-media-process-text">
                Processing...
              </div>
              <div className="videos-layout-list-items-box-media-process-line">
                <div
                  className="videos-layout-list-items-box-media-process-line-inner"
                  style={{ width: process + "%" }}
                ></div>
              </div>
            </div>
          )}
        </Link>

        <div className="videos-layout-list-items-box-content">
          <Link className="videos-layout-list-items-box-title">{title}</Link>
          {size && (
            <div className="videos-layout-list-items-box-label">
              Size: {sizeFormatBytes(size)}
            </div>
          )}
        </div>
        <ButtonGroup
          color="inherit"
          variant="text"
          fullWidth
          className="videos-layout-list-items-box-bottom"
          disableRipple
          style={{ display: "block" }}
        >
          {" "}
        </ButtonGroup>
      </div>
    </Box>
  );
};

export default VideoProcessItem;
