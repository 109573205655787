import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { useGlobalValue } from "../../GlobalContext";
import { UploadIcon } from "../../icons";
import { SESSION_AUTH } from "../../common/auth";
import { isValidUrl } from "../../common/utltis";
import { VIDEO_TYPE_ALLOW } from "../../common/constants";

const VideoUploadButtonUrl = (props) => {
  const { setLoginRequest, videoUploadRequest } = useGlobalValue();
  const { classes, onCallBack } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [file, setFile] = useState(null);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
    }
    return () => null;
  }, [isUserSession]);

  const handleChange = (file) => {
    setFile(file);
    if (!userData) {
      setLoginRequest(true);
    }
  };

  const urlUpload = async () => {
    if (!videoUrl) {
      return toast("Please paste video url");
    } else if (!isValidUrl(videoUrl)) {
      return toast("Video url is not valid");
    } else {
      videoUploadRequest(videoUrl, "url", userData, LOGOUT_USER_SESSION);
      setVideoUrl("");
    }
  };

  useEffect(() => {
    if (file && userData) {
      videoUploadRequest(file, "file", userData, LOGOUT_USER_SESSION);
      setFile("");
      navigate("/dashboard");
      if (onCallBack) {
        onCallBack();
      }
    }
    return () => null;
  }, [
    file,
    userData,
    videoUploadRequest,
    LOGOUT_USER_SESSION,
    navigate,
    onCallBack,
  ]);

  return (
    <div className={`videos-uploadBox-withUrl ${classes}`}>
      <Button
        variant="outlined"
        className="videos-uploadBox videos-btn videos-btn-primary"
        startIcon={<UploadIcon />}
        type="button"
      >
        Upload Video
        <FileUploader
          handleChange={handleChange}
          name="file"
          types={VIDEO_TYPE_ALLOW}
        />
      </Button>
      <div className="videos-uploadBox-withUrl-col">
        <input
          value={videoUrl}
          type="url"
          className="videos-uploadBox-withUrl-input"
          placeholder="Paste a video URL"
          onChange={(e) => setVideoUrl(e.target.value)}
        />
      </div>
      <Button
        variant="outlined"
        className="videos-btn videos-btn-secondary"
        type="button"
        onClick={urlUpload}
      >
        Upload
      </Button>
    </div>
  );
};

export default VideoUploadButtonUrl;
