import React from "react";

export function ZapIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icons / 20 / zap" clipPath="url(#clip0_101_61)">
        <path
          id="Vector"
          d="M9.2755 2.07129L2.60885 10.0713H8.60884L7.94217 15.4046L14.6088 7.40461H8.60884L9.2755 2.07129Z"
          stroke="#E3C050"
          strokeWidth="1.336"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_101_61">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.608856 0.737305)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
