import React, { useState } from "react";
import { AiFillLinkedin, AiOutlineTwitter } from "react-icons/ai";
import { GrFacebookOption } from "react-icons/gr";
import { ImEmbed2 } from "react-icons/im";
import { MdWhatsapp } from "react-icons/md";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
//import { Cross } from "../../icons";
import EmbedModal from "./EmbedModal";

const ShareModal = (props) => {
  const { cancel, videoLink, title, embedUrl } = props;
  const [embedModalOpen, setEmbedModalOpen] = useState(false);

  const copyUrl = () => {
    navigator.clipboard.writeText(videoLink);
    toast("URL Copied!");
  };

  return (
    <>
      <Dialog
        fullWidth
        open={true}
        onClose={cancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="videos-dialog"
        maxWidth="xs"
      >
        <DialogTitle className="text-center">
          Share <br /> <small>{title}</small>
        </DialogTitle>
        <DialogContent className="text-center mt-20">
          <div className="videos-share">
            <button
              className="videos-share-btn"
              onClick={() => setEmbedModalOpen(true)}
            >
              <span className="videos-share-btn-icon embed">
                <ImEmbed2 />
              </span>
              <span className="videos-share-btn-label">Embed</span>
            </button>
            <Link
              to={`https://api.whatsapp.com/send/?text=${videoLink}?type=custom_url&app_absent=0`}
              target="_blank"
              className="videos-share-btn "
            >
              <span className="videos-share-btn-icon whatsapp">
                <MdWhatsapp />
              </span>
              <span className="videos-share-btn-label">Whatsapp</span>
            </Link>

            <TwitterShareButton
              className="videos-share-btn "
              url={videoLink}
              title={title}
              via="6ix"
              hashtags={["6ixVideo"]}
            >
              <span className="videos-share-btn-icon twitter">
                <AiOutlineTwitter />
              </span>
              <span className="videos-share-btn-label">Twitter</span>
            </TwitterShareButton>

            <FacebookShareButton
              hashtag="#6ixVideo"
              url={videoLink}
              quote={title}
              className="videos-share-btn "
            >
              <span className="videos-share-btn-icon facebook">
                <GrFacebookOption />
              </span>
              <span className="videos-share-btn-label">Facebook</span>
            </FacebookShareButton>

            <LinkedinShareButton
              className="videos-share-btn "
              url={videoLink}
              title={title}
              summary={title}
            >
              <span className="videos-share-btn-icon linkedin">
                <AiFillLinkedin />
              </span>
              <span className="videos-share-btn-label">LinkedIn</span>
            </LinkedinShareButton>
          </div>

          <div className="videos-share-url">
            <span className="videos-share-url-text">{videoLink}</span>
            <Button
              type="button"
              variant="contained"
              className="videos-btn videos-btn-filled videos-btn-sm"
              onClick={copyUrl}
            >
              Copy
            </Button>
          </div>
        </DialogContent>
        <DialogActions className="videos-dialog-action">
          <Button
            variant="outlined"
            className="videos-btn  videos-btn-secondary"
            onClick={cancel}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* <div
        className="videosPopup videosPopup-sm"
        onClick={() => {
          close(false);
        }}
      >
        <div
          className="videosPopup-inner"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className="videosPopup-close"
            onClick={() => {
              close(false);
            }}
          >
            <Cross />
          </div>
          <div className="videosPopup-header">
            <div className="videosPopup-header-heading">Share</div>
          </div>
          <div className="videosPopup-content text-center"></div>
        </div>
      </div> */}

      {embedModalOpen && (
        <EmbedModal
          embedUrl={embedUrl}
          title={title}
          open={embedModalOpen}
          cancel={() => {
            setEmbedModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default ShareModal;
