import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import {
  //AnalyticsIcon,
  ClockIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  EmbedIcon,
  LinkIcon,
  MoreIcon,
  PrivacyIcon,
  ReplaceIcon,
  ThumbnailIcon,
} from "../../icons";
import { STATUS_LIST } from "../../common/constants";

const DashboarditemData = (props) => {
  const { actionCallback, itemData } = props;

  const [moreAction, setMoreAction] = useState(false);
  const anchorRef = useRef(HTMLDivElement || null);

  const actionMenuItem = (type) => {
    actionCallback(type, itemData);
    setMoreAction(false);
  };

  const handleToggle = () => {
    setMoreAction((prevOpen) => !prevOpen);
  };

  const actionClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target)) {
      return;
    }
    setMoreAction(false);
  };

  return (
    <Box className="videos-layout-list-items-box">
      <div className="videos-layout-list-items-box-inner">
        <Link
          to={itemData?.videoLink}
          className="videos-layout-list-items-box-media"
          title={itemData?.title}
        >
          {itemData?.status === STATUS_LIST?.process?.value ? (
            <div className="videos-layout-list-items-box-media-process">
              <div className="videos-layout-list-items-box-media-process-text">
                <ClockIcon />
                Video publishing soon
              </div>
            </div>
          ) : itemData?.status === STATUS_LIST?.error?.value ? (
            <div className="videos-layout-list-items-box-media-process">
              <div className="videos-layout-list-items-box-media-process-text">
                <ClockIcon />
                Video uploading error
              </div>
            </div>
          ) : itemData?.thumb ? (
            <img src={itemData?.thumb} alt={itemData?.title} />
          ) : null}
          <div className="videos-layout-list-items-box-media-action">
            {itemData?.mediaProcess && (
              <div className="videos-layout-list-items-box-media-action-indicate">
                Process
              </div>
            )}
            {itemData?.status === STATUS_LIST?.active?.value && (
              <div className="videos-layout-list-items-box-media-action-views">
                {itemData?.views?.count || "0"} Views
              </div>
            )}
          </div>
        </Link>

        <div className="videos-layout-list-items-box-content">
          <Link
            to={itemData?.videoLink}
            className="videos-layout-list-items-box-title"
          >
            {itemData?.title}
          </Link>
          <div className="videos-layout-list-items-box-copy">
            <div className="videos-layout-list-items-box-copy-url">
              {itemData?.videoLink}
            </div>
            <div
              className="videos-layout-list-items-box-copy-btn"
              onClick={() => actionMenuItem("copy")}
            >
              <LinkIcon />
              Copy Link
            </div>
          </div>
        </div>

        <ButtonGroup
          color="inherit"
          variant="text"
          fullWidth
          className="videos-layout-list-items-box-bottom"
          disableRipple
        >
          <Button
            startIcon={<EmbedIcon />}
            className="videos-layout-list-items-box-bottom-btn"
            onClick={() => actionMenuItem("embed")}
            disabled={itemData?.status !== STATUS_LIST?.active?.value}
          >
            Embed
          </Button>
          <Button
            startIcon={<EditIcon />}
            className="videos-layout-list-items-box-bottom-btn"
            onClick={() => actionMenuItem("edit")}
          >
            Edit video
          </Button>
          <Button
            startIcon={<MoreIcon />}
            className={`videos-layout-list-items-box-bottom-btn ${moreAction ? "active" : ""}`}
            size="small"
            aria-controls={moreAction ? "videos-list-item-menu" : undefined}
            aria-expanded={moreAction ? "true" : undefined}
            aria-label="select action"
            aria-haspopup="menu"
            onClick={handleToggle}
            ref={anchorRef}
          >
            More
          </Button>
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={moreAction}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            placement="bottom-end"
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={actionClose}>
                    {itemData?.status === STATUS_LIST?.active?.value ? (
                      <MenuList
                        id="videos-list-item-menu"
                        autoFocusItem
                        className="videos-layout-list-items-box-bottom-dropdown"
                      >
                        {/* <MenuItem
                          disabled
                          onClick={() => actionMenuItem("analytics")}
                          className="videos-layout-list-items-box-bottom-dropdown-btn"
                        >
                          <AnalyticsIcon />
                          Analytics
                        </MenuItem> */}
                        <MenuItem
                          onClick={() => actionMenuItem("thumb")}
                          className="videos-layout-list-items-box-bottom-dropdown-btn"
                        >
                          <ThumbnailIcon /> Edit Thumbnail
                        </MenuItem>
                        <MenuItem
                          onClick={() => actionMenuItem("privacy")}
                          className="videos-layout-list-items-box-bottom-dropdown-btn"
                        >
                          <PrivacyIcon />
                          Privacy
                        </MenuItem>
                        <MenuItem
                          onClick={() => actionMenuItem("replace")}
                          className="videos-layout-list-items-box-bottom-dropdown-btn"
                          disabled={itemData?.mediaProcess}
                        >
                          <ReplaceIcon />
                          Replace video
                        </MenuItem>
                        <MenuItem
                          onClick={() => actionMenuItem("download")}
                          className="videos-layout-list-items-box-bottom-dropdown-btn"
                        >
                          <DownloadIcon />
                          Download
                        </MenuItem>
                        <MenuItem
                          onClick={() => actionMenuItem("delete")}
                          className="videos-layout-list-items-box-bottom-dropdown-btn"
                        >
                          <DeleteIcon />
                          Delete
                        </MenuItem>
                      </MenuList>
                    ) : itemData?.status === STATUS_LIST?.error?.value ? (
                      <MenuList
                        id="videos-list-item-menu"
                        autoFocusItem
                        className="videos-layout-list-items-box-bottom-dropdown"
                      >
                        <MenuItem
                          onClick={() => actionMenuItem("thumb")}
                          className="videos-layout-list-items-box-bottom-dropdown-btn"
                        >
                          <ThumbnailIcon /> Edit Thumbnail
                        </MenuItem>

                        <MenuItem
                          onClick={() => actionMenuItem("replace")}
                          className="videos-layout-list-items-box-bottom-dropdown-btn"
                          disabled={itemData?.mediaProcess}
                        >
                          <ReplaceIcon />
                          Replace video
                        </MenuItem>

                        <MenuItem
                          onClick={() => actionMenuItem("delete")}
                          className="videos-layout-list-items-box-bottom-dropdown-btn"
                        >
                          <DeleteIcon />
                          Delete
                        </MenuItem>
                      </MenuList>
                    ) : itemData?.status === STATUS_LIST?.process?.value ? (
                      <MenuList
                        id="videos-list-item-menu"
                        autoFocusItem
                        className="videos-layout-list-items-box-bottom-dropdown"
                      >
                        <MenuItem
                          onClick={() => actionMenuItem("privacy")}
                          className="videos-layout-list-items-box-bottom-dropdown-btn"
                        >
                          <PrivacyIcon />
                          Privacy
                        </MenuItem>
                      </MenuList>
                    ) : null}
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </ButtonGroup>
      </div>
    </Box>
  );
};

export default DashboarditemData;
