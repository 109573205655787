import React from "react";
import VideoUploadButtonUrl from "../Uploader/VideoUploadButtonUrl";
import ExploreList from "./ExploreList";

const Explore = () => {
  return (
    <div className="videos-layout-content-container">
      <VideoUploadButtonUrl classes="videos-layout-list-uploadBox" />
      <ExploreList />
    </div>
  );
};

export default Explore;
