import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/6ixlogo.png";

const HeaderLogo = () => {
  return (
    <React.Fragment>
      <div className="videos-layout-header-logo">
        <a title="6ix App" href="https://app.6ix.com">
          <img
            className="videos-layout-header-logo-img"
            src={logo}
            alt="logo"
          />
        </a>
        <Link
          title="6ix Videos"
          to="/"
          className="videos-layout-header-logo-text"
        >
          videos
        </Link>
      </div>
    </React.Fragment>
  );
};

export default HeaderLogo;
