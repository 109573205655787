import React from "react";
export function EditIcon() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.732 3.22642C12.8989 3.05952 13.097 2.92713 13.3151 2.83681C13.5331 2.74649 13.7668 2.7 14.0029 2.7C14.2389 2.7 14.4726 2.74649 14.6907 2.83681C14.9087 2.92713 15.1069 3.05952 15.2738 3.22642C15.4407 3.39332 15.5731 3.59146 15.6634 3.80952C15.7537 4.02758 15.8002 4.2613 15.8002 4.49732C15.8002 4.73335 15.7537 4.96707 15.6634 5.18513C15.5731 5.40319 15.4407 5.60133 15.2738 5.76823L6.69518 14.3468L3.2002 15.3L4.15337 11.805L12.732 3.22642Z"
        stroke="#C0C0C0"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default EditIcon;
