import React, { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import {
  AiFillDislike,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";
import { toast } from "react-toastify";
import likeGif from "../../assets/images/like-animation.gif";
import { useGlobalValue } from "../../GlobalContext";
import { SESSION_AUTH } from "../../common/auth";
import { VIDEO_SERVICES } from "../../services";

const VideoLike = (props) => {
  const { videoId, likeData } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [loading, setLoading] = useState(false);
  const { setLoginRequest } = useGlobalValue();
  const [isLike, setIsLike] = useState("");
  const [countLike, setCountLike] = useState(0);
  const [showLikeGif, setShowLikeGif] = useState(false);
  useEffect(() => {
    setIsLike(likeData?.type);
    setCountLike(likeData?.count);
  }, [likeData]);

  const likeEffect = () => {
    setShowLikeGif(true);
    setTimeout(() => {
      setShowLikeGif(false);
    }, 1000);
  };

  const videoLike = (type) => {
    if (loading) return;
    if (!isUserSession) return setLoginRequest(true);
    setLoading(true);
    setIsLike(type);
    VIDEO_SERVICES.like({
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
      id: videoId,
      type: type,
    })
      .then((data) => {
        if (data.code === 200) {
          setIsLike(data?.data?.type || "");
          setCountLike(data?.data?.count || 0);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          toast(data.message);
        }
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="videos-like">
        <button
          className="videos-like-btn videos-like-btn-like"
          onClick={() => {
            videoLike(isLike === "like" ? "unlike" : "like");
            likeEffect();
          }}
        >
          {isLike === "like" ? (
            <span className="videos-like-btn-position">
              <IconContext.Provider
                value={{
                  className: "videos-like-btn-animation",
                }}
              >
                <AiFillLike />
              </IconContext.Provider>{" "}
              {showLikeGif && (
                <img className="videos-like-btn-effect" src={likeGif} alt="" />
              )}
            </span>
          ) : (
            <AiOutlineLike />
          )}
          {countLike}
        </button>
        <button
          className="videos-like-btn videos-like-btn-dislike"
          onClick={() => {
            videoLike(isLike === "dislike" ? "unlike" : "dislike");
          }}
        >
          {isLike === "dislike" ? <AiFillDislike /> : <AiOutlineDislike />}
        </button>
      </div>
    </React.Fragment>
  );
};

export default VideoLike;
