import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import FullPageLoader from "../Common/FullPageLoader";
import VideoUploadReplace from "../Uploader/VideoUploadReplace";
import { SESSION_AUTH } from "../../common/auth";
import { VIDEO_SERVICES } from "../../services";

const VideoReplace = (props) => {
  const { request, video, cancel, accept } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [loading, setLoading] = useState(false);

  const onSave = (file, type) => {
    if (file) {
      const parma = new FormData();
      parma.append("userId", isUserSession.userId);
      parma.append("org", isUserSession?.org?.id);
      parma.append("id", video._id);
      if (type === "file") {
        parma.append("video", file, file.name);
      } else {
        parma.append("video", file);
      }

      setLoading(true);
      VIDEO_SERVICES.replaceVideo(parma)
        .then((data) => {
          if (data.code === 200) {
            toast(data.message);
            accept();
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
            cancel();
          } else {
            toast(data.message);
          }
        })
        .catch((err) => {
          toast(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <Dialog
      fullWidth
      open={request}
      onClose={cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="videos-dialog"
    >
      {loading && <FullPageLoader />}
      <DialogTitle className="text-center">
        Replace video <br /> <small>{video.title}</small>
      </DialogTitle>
      <DialogContent className="text-center mt-20 mb-20">
        <VideoUploadReplace onCallBack={onSave} />
      </DialogContent>
      <DialogActions className="videos-dialog-action">
        <Button
          variant="outlined"
          onClick={cancel}
          className="videos-dialog-btn  videos-btn videos-btn-secondary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VideoReplace;
