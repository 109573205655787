import { useState } from "react";
import { toast } from "react-toastify";
import {
  auth,
  signInWithGoogle,
  signInWithUserEmailLink,
} from "../../../firebase";
import { Cross, GoogleIcon } from "../../../icons";
import FullPageLoader from "../../Common/FullPageLoader";
import { useAuthState } from "react-firebase-hooks/auth";
import { SESSION_AUTH } from "../../../common/auth";
import { isValidEmail } from "../../../common/constants";
import "./Login.scss";

export function LoginPopup(props) {
  const { SET_USER_SESSION } = SESSION_AUTH();
  const { handleLogin, dropOut } = props;
  const [loader, setLoader] = useState(false);
  const [loginLinkMSG, setLoginLinkMSG] = useState("");

  const [, loading] = useAuthState(auth);
  const [email, setEmail] = useState("");

  const loginWIthEmail = async () => {
    if (email && isValidEmail(email)) {
      setLoader(true);
      const data = await signInWithUserEmailLink(email);
      if (data) {
        setLoginLinkMSG(data);
      }
      setLoader(false);
    } else {
      toast("Enter your email address");
    }
  };
  const loginWithGoogle = async () => {
    setLoader(true);
    const data = await signInWithGoogle();

    if (data?.code === 200 && data?.data) {
      SET_USER_SESSION(data?.data);
      if (handleLogin) handleLogin();
    }

    setLoader(false);
  };
  return (
    <>
      <div
        align="center"
        direction="column"
        className="videosPopup videosPopup-sm userLogin"
        onClick={() => {
          if (dropOut) {
            handleLogin();
          }
        }}
      >
        <div
          className="videosPopup-inner"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {loading || loader ? <FullPageLoader /> : null}
          <>
            <div className="videosPopup-header">
              <div className="videosPopup-header-heading">
                Sign up or log in
              </div>
              <div className="videosPopup-header-desc">
                If you’ve already registered, this will log you in
              </div>
            </div>

            <div className="videosPopup-close" onClick={handleLogin}>
              <Cross />
            </div>

            <div className="videosPopup-content">
              <div className="userLogin-social">
                <button
                  onClick={loginWithGoogle}
                  type="button"
                  className="userLogin-social-btn"
                >
                  <GoogleIcon />
                  Continue with Google
                </button>
              </div>
              <div className="userLogin-or">
                <span className="userLogin-or-text">or</span>
              </div>

              <div className="userLogin-email custom-form">
                {loginLinkMSG ? (
                  <>
                    <div className="userLogin-email-msg">{loginLinkMSG}</div>
                    <div
                      className="userLogin-email-link"
                      onClick={() => {
                        setLoginLinkMSG("");
                      }}
                    >
                      Did not get the link?
                    </div>
                  </>
                ) : (
                  <>
                    <input
                      placeholder="your@email.com"
                      className="userLogin-email-input"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          if (!event.shiftKey) {
                            loginWIthEmail();
                          }
                        }
                      }}
                    />

                    <button
                      type="button"
                      className="userLogin-email-btn"
                      onClick={loginWIthEmail}
                      disabled={loader || loading}
                    >
                      Continue with Email
                    </button>
                  </>
                )}
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
}
