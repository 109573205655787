import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { Button } from "@mui/material";
import { UploadIcon } from "../../icons";
import { IMAGE_TYPE_ALLOW } from "../../common/constants";

const VideoUploadThumb = (props) => {
  const { classes, onCallBack } = props;

  return (
    <div className={`videos-uploadBox videos-uploadBox-withDrag ${classes}`}>
      <FileUploader
        className="videos-uploadBox-withDrag-frame"
        handleChange={onCallBack}
        name="file"
        types={IMAGE_TYPE_ALLOW}
      />
      <div className="videos-uploadBox-withDrag-content">
        <Button
          variant="outlined"
          className="videos-btn videos-btn-md videos-btn-primary"
          type="button"
          startIcon={<UploadIcon />}
        >
          upload image
        </Button>
        <div className="videos-uploadBox-withDrag-content-info">
          You can also drag and drop a image file
        </div>
      </div>
    </div>
  );
};

export default VideoUploadThumb;
