import React, { useState } from "react";
import { IoMdCheckmark } from "react-icons/io";

const ChoosePlan = () => {
  const [tabIndex, setTabIndex] = useState(false);

  const PlansArray = [
    {
      Type: "Free",
      Price: "$0",
      MonthlyPrice: "$0",
      timePeriod: "Per Month",
      activePlan: true,
      PlanDetails: [
        "250 MB / 10 min limit",
        "90 day video retention",
        "Stream your videos in HD",
        "Ad-supported playback",
      ],
    },
    {
      Type: "Basic",
      Price: "$8.99",
      DiscountedPRice: "$8.99",
      MonthlyPrice: "$4.99",
      MonthlyDiscountedPRice: "$3.99",
      timePeriod: "Per month / billed yearly",
      activePlan: false,
      PlanDetails: [
        "No size or length limits",
        "500GB cloud storage",
        "Stream in 4K UltraHD",
        "No Ads",
      ],
    },
    {
      Type: "Pro",
      Price: "$19.99",
      DiscountedPRice: "$14.99",
      MonthlyPrice: "$8.99",
      MonthlyDiscountedPRice: "$6.99",
      timePeriod: "Per month / billed yearly",
      activePlan: false,
      PlanDetails: [
        "Everything in the Basic Plan",
        "1TB cloud storage",
        "2TB video bandwidth for your audience",
        "Video security for your domain",
      ],
    },
    {
      Type: "Business",
      Price: "$49",
      DiscountedPRice: "$39",
      MonthlyPrice: "$29",
      MonthlyDiscountedPRice: "$15",
      timePeriod: "Per month / billed yearly",
      activePlan: false,
      PlanDetails: [
        "Everything in the Pro Plan",
        "2TB cloud storage",
        "3TB video bandwidth for your audience",
        "Priority Support",
      ],
    },
  ];
  return (
    <div className="videos-layout-content-container">
      <div className="main-layout-video-wraper choose_plan">
        <div className="video-home-heading mt-8 mb-3">
          <span className="title"> Choose a plan </span>
        </div>

        <div className="d-flex justify-center items-center flex-column tab_section">
          <div className="d-flex justify-center items-center">
            <button
              className={`cursor-pointer grey-color-text choose-tab-padding choose-tab-radius-left bg-transparent font-bold  text-sm ${
                tabIndex
                  ? "border-yellow white-color-text"
                  : "border-grey grey-color-text"
              }`}
              onClick={() => setTabIndex(true)}
            >
              Monthly
            </button>
            <button
              className={`cursor-pointer bg-transparent choose-tab-padding choose-tab-radius-right text-sm ${
                tabIndex
                  ? "border-grey grey-color-text"
                  : "border-yellow white-color-text"
              }`}
              onClick={() => setTabIndex(false)}
            >
              {" "}
              Yearly &nbsp;&nbsp;&nbsp;
              <span className="font-bold yellow-color-text text-sm ">
                Save up to 25%
              </span>
            </button>
          </div>
          <div className="mt-10">
            {tabIndex ? (
              <div className="p-07 d-flex items-center justify-center gap-5 items-end choose-plan-container">
                {PlansArray.map((item, key) => {
                  return (
                    <div
                      className={`${
                        item?.Type === "Pro" &&
                        "p-1 yellow-color-bg radius-4 most_popular_card"
                      } plan-card-inner`}
                    >
                      <div className="cursor-pointer hover_item_yellow_border plan-card  plan_card_height dark_grey-color radius-4 p-06  ">
                        <div className="w-100">
                          <div className="w-100 d-flex flex-column gap-6">
                            <span className="font-bold text-2xl">
                              {item?.Type}
                            </span>
                            <div className="d-flex gap-4 mt-6 items-end">
                              <span
                                className={`font-bold ${
                                  item?.MonthlyDiscountedPRice
                                    ? "text-2xl charcol-color-text text-line-through"
                                    : "text-3xl "
                                }`}
                              >
                                {item?.MonthlyPrice}
                              </span>
                              <span className="font-bold text-3xl">
                                {item?.MonthlyDiscountedPRice}
                              </span>
                            </div>
                            <span className="text-base mt-6">
                              {item?.timePeriod}
                            </span>
                          </div>
                          <button
                            className={`cursor-pointer w-100 plan-card-btn text-sm border-grey  bg-transparent radius-2 mt-10 ${
                              !item?.activePlan
                                ? "border-yellow dark-black-bg font-bold white-color-text"
                                : "grey-color-text"
                            }`}
                          >
                            {item?.activePlan
                              ? "CURRENT PLAN"
                              : "START FREE TRIAL"}
                          </button>
                          <div className="plan-card-details mt-11">
                            {item?.PlanDetails.map((item, key) => {
                              return (
                                <div className="d-flex items-center gap-4 mt-3">
                                  <IoMdCheckmark />
                                  <span className="text-sm">{item}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="p-07 d-flex items-center justify-center gap-5 items-end choose-plan-container">
                {PlansArray.map((item, key) => {
                  return (
                    <div
                      className={`${
                        item?.Type === "Pro" &&
                        "p-1 yellow-color-bg radius-4 most_popular_card"
                      } plan-card-inner`}
                    >
                      <div className="cursor-pointer hover_item_yellow_border plan-card  plan_card_height dark_grey-color radius-4 p-06  ">
                        <div className="w-100">
                          <div className="w-100 d-flex flex-column gap-6">
                            <span className="font-bold text-2xl">
                              {item?.Type}
                            </span>
                            <div className="d-flex gap-4 mt-6 items-end">
                              <span
                                className={`font-bold ${
                                  item?.DiscountedPRice
                                    ? "text-2xl charcol-color-text text-line-through"
                                    : "text-3xl "
                                }`}
                              >
                                {item?.Price}
                              </span>
                              <span className="font-bold text-3xl">
                                {item?.DiscountedPRice}
                              </span>
                            </div>
                            <span className="text-base mt-6">
                              {item?.timePeriod}
                            </span>
                          </div>
                          <button
                            className={`cursor-pointer w-100 plan-card-btn text-sm border-grey  bg-transparent radius-2 mt-10 ${
                              !item?.activePlan
                                ? "border-yellow dark-black-bg font-bold white-color-text"
                                : "grey-color-text"
                            }`}
                          >
                            {item?.activePlan
                              ? "CURRENT PLAN"
                              : "START FREE TRIAL"}
                          </button>
                          <div className="plan-card-details mt-11">
                            {item?.PlanDetails.map((item, key) => {
                              return (
                                <div className="d-flex items-center gap-4 mt-3">
                                  <IoMdCheckmark />
                                  <span className="text-sm">{item}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className="d-flex justify-center items-center gap-5 mt-5 pb-8 choose_plan_footer">
          <span>
            <span className="font-bold"> Need a plan without limits?</span> Our
            Enterprise plan gives you custom bandwidth pricing, high-volume
            storage, reporting, and more.
          </span>
          <span className="yellow-color-text font-bold">Contact sales</span>
        </div>
      </div>
    </div>
  );
};

export default ChoosePlan;
