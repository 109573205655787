import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import FullPageLoader from "../Common/FullPageLoader";
import { SESSION_AUTH } from "../../common/auth";
import { VIDEO_SERVICES } from "../../services";
import { PRIVACY_LIST } from "../../common/constants";

const VideoPrivacy = (props) => {
  const { request, video, cancel, accept } = props;
  const [privacy, setPrivacy] = useState("");
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPrivacy(video?.privacy || "");
  }, [video]);
  const onSave = () => {
    setLoading(true);
    VIDEO_SERVICES.privacyVideo({
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
      id: video?._id,
      privacy: privacy,
    })
      .then((data) => {
        if (data.code === 200) {
          toast(data.message);
          accept();
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          cancel();
        } else {
          toast(data.message);
        }
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={request}
      onClose={cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="videos-dialog"
    >
      {loading && <FullPageLoader />}
      <DialogTitle className="text-center" style={{ overflow: "hidden" }}>
        Privacy <br /> <small>{video.title}</small>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth className="mt-20 mb-20">
          <InputLabel id="video-privacy-list-label">Privacy</InputLabel>
          <Select
            labelId="video-privacy-list-label"
            id="video-privacy-list"
            value={privacy}
            label="Privacy"
            onChange={(e) => {
              setPrivacy(e.target.value);
            }}
          >
            {Object.keys(PRIVACY_LIST).map((key, index) => (
              <MenuItem key={index} value={PRIVACY_LIST?.[key]?.value}>
                {PRIVACY_LIST?.[key]?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions className="videos-dialog-action">
        {video?.privacy !== privacy ? (
          <>
            <Button
              variant="outlined"
              onClick={cancel}
              className="videos-dialog-btn  videos-btn videos-btn-secondary"
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              onClick={onSave}
              className="videos-dialog-btn videos-btn videos-btn-primary"
            >
              Save
            </Button>
          </>
        ) : (
          <Button
            variant="outlined"
            onClick={cancel}
            className="videos-dialog-btn  videos-btn videos-btn-secondary"
          >
            Okay
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default VideoPrivacy;
