import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ClockIcon } from "../../icons";
import Disclaimer from "../Common/Disclaimer";
import FullPageLoader from "../Common/FullPageLoader";
import SEO from "../Common/SEO";
import VideosPlayer from "../Player/VideosPlayer";
import VideosDetail from "./VideosDetail";
import { SESSION_AUTH } from "../../common/auth";
import { VIDEO_SERVICES } from "../../services";
import { STATUS_LIST } from "../../common/constants";
import "./style.scss";

const Videos = () => {
  const { videoId } = useParams();
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();

  const dataFetched = useRef(false);
  const [loading, setLoading] = useState(true);
  const [videoData, setVideoData] = useState("");

  useEffect(() => {
    if (!videoId || dataFetched?.current) return;
    dataFetched.current = true;
    setLoading(true);
    VIDEO_SERVICES.getDetail({
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
      id: videoId,
    })
      .then((data) => {
        if (data.code === 200) {
          setVideoData(data.data || "");
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          toast(data.message);
        }
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataFetched, videoId, isUserSession, LOGOUT_USER_SESSION]);

  return (
    <React.Fragment>
      {loading ? (
        <FullPageLoader />
      ) : videoData ? (
        <div className="videos-layout-content-container">
          <SEO
            title={videoData?.title + " | 6ix Videos"}
            url={videoData?.videoLink}
            description={videoData?.description}
            image={videoData?.thumb}
          />
          {videoData?.status === STATUS_LIST.active.value &&
          videoData?.video ? (
            <VideosPlayer videoData={videoData} />
          ) : videoData?.status === STATUS_LIST.error.value ? (
            <div className="videos-layout-video-detail-mediaMsg">
              <ClockIcon /> Video uploading error
            </div>
          ) : videoData?.status === STATUS_LIST.baned.value ? (
            <div className="videos-layout-video-detail-mediaMsg">
              <ClockIcon /> Video baned
            </div>
          ) : videoData?.status === STATUS_LIST.process.value ? (
            <div className="videos-layout-video-detail-mediaMsg">
              <ClockIcon />
              Video publishing soon
            </div>
          ) : videoData?.status === STATUS_LIST.delete.value ? (
            <div className="videos-layout-video-detail-mediaMsg">
              <ClockIcon /> Video deleted
            </div>
          ) : (
            <div className="videos-layout-video-detail-mediaMsg">
              <ClockIcon /> Media not available
            </div>
          )}

          <div className="videos-layout-video-detail-divider"></div>
          <VideosDetail videoData={videoData} />
          <div className="videos-layout-video-detail-divider"></div>
          <Disclaimer />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default Videos;
