import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import { saveAs } from "file-saver";
import { useGlobalValue } from "../../GlobalContext";
import { DeleteIcon } from "../../icons";
import FullPageLoader from "../Common/FullPageLoader";
import { ConfirmModal } from "../Models/ConfirmModal";
import EmbedModal from "../Models/EmbedModal";
import DashboardListItem from "./DashboardListItem";
import ThumbReplace from "./ThumbReplace";
import VideoEdit from "./VideoEdit";
import VideoPrivacy from "./VideoPrivacy";
import VideoProcessItem from "./VideoProcessItem";
import VideoReplace from "./VideoReplace";
import { SESSION_AUTH } from "../../common/auth";
import { VIDEO_SERVICES } from "../../services";

const DashboardList = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { videoRequestList, videoListReload } = useGlobalValue();
  const [dataFetched, setDataFetched] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [editRequest, setEditRequest] = useState(false);
  const [privacyRequest, setPrivacyRequest] = useState(false);
  const [replaceRequest, setReplaceRequest] = useState(false);
  const [thumbRequest, setThumbRequest] = useState(false);
  const [embedRequest, setEmbedRequest] = useState(false);
  const [processList, setProcessList] = useState([]);
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();
  const [listData, setListData] = useState([]);

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      navigate("/");
    }
  }, [isUserSession, navigate]);
  useEffect(() => {
    setDataFetched(true);
  }, [videoListReload]);
  useEffect(() => {
    setProcessList(videoRequestList ? [...videoRequestList] : []);
  }, [videoRequestList]);

  useEffect(() => {
    if (!userData || !dataFetched) return;
    setDataFetched(false);
    setLoading(true);
    VIDEO_SERVICES.list({ userId: userData?.userId, org: userData?.org?.id })
      .then((data) => {
        if (data.code === 200) {
          setListData(data.data || []);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          toast(data.message);
        }
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataFetched, userData, LOGOUT_USER_SESSION]);

  const actionCallback = (type, item) => {
    if (!type || !item) return;
    setSelectedVideo(item);
    switch (type) {
      case "edit":
        setEditRequest(true);
        break;
      case "embed":
        setEmbedRequest(true);
        break;
      case "analytics":
        break;
      case "thumb":
        setThumbRequest(true);
        break;
      case "privacy":
        setPrivacyRequest(true);
        break;
      case "replace":
        setReplaceRequest(true);
        break;
      case "download":
        downloadRequest(item);
        break;
      case "delete":
        setDeleteRequest(true);
        break;
      case "copy":
        navigator.clipboard.writeText(item?.videoLink);
        toast("Video link copied!");
        break;

      default:
        break;
    }
  };
  const acceptDeleteRequest = () => {
    setLoading(true);
    VIDEO_SERVICES.deleteVideo({
      userId: userData?.userId,
      org: userData?.org?.id,
      id: selectedVideo?._id,
    })
      .then((data) => {
        if (data.code === 200) {
          toast(data.message);
          setDataFetched(true);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          toast(data.message);
        }
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const downloadRequest = (video) => {
    setLoading(true);
    VIDEO_SERVICES.videoDownload({
      userId: userData?.userId,
      org: userData?.org?.id,
      id: video?._id,
    })
      .then((data) => {
        if (data.code === 200) {
          if (data?.data?.video) {
            saveAs(data?.data?.video, "video", { autoBom: true });
            toast("Downloaing in process");
          }
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          toast(data.message);
        }
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      <Box className="videos-layout-list-items">
        {processList
          .filter((item) => !item.hide)
          .sort((a, b) => b.id - a.id)
          .map((listItem, keys) => {
            return <VideoProcessItem {...listItem} key={keys} />;
          })}
        {listData.map((listItem, key) => {
          return (
            <DashboardListItem
              actionCallback={actionCallback}
              itemData={listItem}
              key={key}
            />
          );
        })}
      </Box>
      {loading && <FullPageLoader normal />}
      {deleteRequest && selectedVideo && (
        <ConfirmModal
          request={deleteRequest}
          icon={<DeleteIcon type="round" />}
          title="Delete video?"
          message={selectedVideo?.title}
          cancel={() => {
            setDeleteRequest(false);
            setSelectedVideo("");
          }}
          accept={() => {
            acceptDeleteRequest();
            setDeleteRequest(false);
          }}
          yesLabel="YES, DELETE"
        />
      )}
      {editRequest && selectedVideo && (
        <VideoEdit
          request={editRequest}
          video={selectedVideo}
          cancel={() => {
            setEditRequest(false);
            setSelectedVideo("");
          }}
          accept={() => {
            setEditRequest(false);
            setDataFetched(true);
            setSelectedVideo("");
          }}
        />
      )}
      {privacyRequest && selectedVideo && (
        <VideoPrivacy
          request={privacyRequest}
          video={selectedVideo}
          cancel={() => {
            setPrivacyRequest(false);
            setSelectedVideo("");
          }}
          accept={() => {
            setPrivacyRequest(false);
            setDataFetched(true);
            setSelectedVideo("");
          }}
        />
      )}
      {replaceRequest && selectedVideo && (
        <VideoReplace
          request={replaceRequest}
          video={selectedVideo}
          cancel={() => {
            setReplaceRequest(false);
            setSelectedVideo("");
          }}
          accept={() => {
            setReplaceRequest(false);
            setDataFetched(true);
            setSelectedVideo("");
          }}
        />
      )}
      {thumbRequest && selectedVideo && (
        <ThumbReplace
          request={thumbRequest}
          video={selectedVideo}
          cancel={() => {
            setThumbRequest(false);
            setSelectedVideo("");
          }}
          accept={() => {
            setThumbRequest(false);
            setDataFetched(true);
            setSelectedVideo("");
          }}
        />
      )}
      {embedRequest && selectedVideo && (
        <EmbedModal
          request={embedRequest}
          embedUrl={`${process.env.REACT_APP_PLATFORM_URL}/embed/${selectedVideo?._id}`}
          title={selectedVideo?.title}
          cancel={() => {
            setEmbedRequest(false);
            setSelectedVideo("");
          }}
        />
      )}
    </React.Fragment>
  );
};

export default DashboardList;
