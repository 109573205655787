import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import FullPageLoader from "../Common/FullPageLoader";
import VideosPlayer from "../Player/VideosPlayer";
import { EMBED_SERVICES } from "../../services";

const VideosEmbed = () => {
  const { videoId } = useParams();

  const dataFetched = useRef(false);
  const [loading, setLoading] = useState(true);
  const [videoData, setVideoData] = useState("");

  useEffect(() => {
    if (!videoId || dataFetched?.current) return;
    dataFetched.current = true;
    setLoading(true);
    EMBED_SERVICES.embed({
      id: videoId,
    })
      .then((data) => {
        setVideoData(data || "");
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [dataFetched, videoId]);

  return (
    <React.Fragment>
      {loading ? (
        <FullPageLoader />
      ) : videoData ? (
        <VideosPlayer videoData={videoData} />
      ) : null}
    </React.Fragment>
  );
};

export default VideosEmbed;
