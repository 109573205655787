import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { saveAs } from "file-saver";
import { useGlobalValue } from "../../GlobalContext";
import { DownloadIcon } from "../../icons";
import FullPageLoader from "../Common/FullPageLoader";
import { SESSION_AUTH } from "../../common/auth";
import { VIDEO_SERVICES } from "../../services";

const VideoDownload = (props) => {
  const { videoId, enbableDownload } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [loading, setLoading] = useState(false);
  const { setLoginRequest } = useGlobalValue();

  const videoDownload = () => {
    if (!enbableDownload) return;
    if (!isUserSession) return setLoginRequest(true);
    setLoading(true);
    VIDEO_SERVICES.videoDownload({
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
      id: videoId,
    })
      .then((data) => {
        if (data.code === 200) {
          if (data?.data?.video) {
            saveAs(data?.data?.video, "video", { autoBom: true });
            toast("Downloaing in process");
          }
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          toast(data.message);
        }
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      {loading && <FullPageLoader />}
      <Button
        disabled={!enbableDownload}
        variant="outlined"
        className="videos-btn videos-btn-sm videos-btn-dark"
        startIcon={<DownloadIcon />}
        type="button"
        onClick={videoDownload}
      >
        Download
      </Button>
    </React.Fragment>
  );
};

export default VideoDownload;
