import React from "react";
export function PrivacyIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 8.25H3.75C2.92157 8.25 2.25 8.92157 2.25 9.75V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V9.75C15.75 8.92157 15.0784 8.25 14.25 8.25Z"
        stroke="#C0C0C0"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 8.25V5.25C5.25 4.25543 5.64509 3.3016 6.34835 2.59834C7.05161 1.89508 8.00544 1.49999 9 1.49999C9.99456 1.49999 10.9484 1.89508 11.6517 2.59834C12.3549 3.3016 12.75 4.25543 12.75 5.25V8.25"
        stroke="#C0C0C0"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default PrivacyIcon;
