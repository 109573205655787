import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import FullLayout from "./Layout/FullLayout";
import DashboardPage from "./pages/DashboardPage";
import DemoPage from "./pages/DemoPage";
import EmbedPage from "./pages/EmbedPage";
import ExplorePage from "./pages/ExplorePage";
import HomePage from "./pages/HomePage";
import { InvestorDemoPage, InvestorRelationsPage } from "./pages/InvestorPage";
import PlanPage from "./pages/PlanPage";
import RefreshPage from "./pages/RefreshPage";
import VideosPage from "./pages/VideosPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<FullLayout />}>
          <Route
            path="/strategic-investor-relations"
            element={<InvestorRelationsPage />}
          />
          <Route
            path="/investor-relations-demo"
            element={<InvestorDemoPage />}
          />
          <Route path="/demo" element={<DemoPage />} />
          <Route path="/embed/:videoId" element={<EmbedPage />} />
        </Route>

        <Route element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/choose-plan" element={<PlanPage />} />

          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/explore" element={<ExplorePage />} />
          <Route path="/video/:videoId" element={<VideosPage />} />
        </Route>
        <Route path="/R_C_P" element={<RefreshPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
