import React from "react";
import InvestorDemo from "../components/Investor/InvestorDemo";
import InvestorRelations from "../components/Investor/InvestorRelations";

export const InvestorRelationsPage = () => {
  return <InvestorRelations />;
};

export const InvestorDemoPage = () => {
  return <InvestorDemo />;
};
