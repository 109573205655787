import React from "react";

export function VolumeIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16666 4.16699L4.99999 7.50033H1.66666V12.5003H4.99999L9.16666 15.8337V4.16699Z"
        stroke="#E9E9E9"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.95 7.05007C13.7311 7.83143 14.17 8.89105 14.17 9.9959C14.17 11.1007 13.7311 12.1604 12.95 12.9417"
        fill="#E9E9E9"
      />
      <path
        d="M15.8917 4.1084C17.4539 5.67113 18.3316 7.79036 18.3316 10.0001C18.3316 12.2098 17.4539 14.329 15.8917 15.8917M12.95 7.05007C13.7311 7.83143 14.17 8.89105 14.17 9.9959C14.17 11.1007 13.7311 12.1604 12.95 12.9417"
        stroke="#E9E9E9"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
