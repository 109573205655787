import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import FullPageLoader from "../Common/FullPageLoader";
import { SESSION_AUTH } from "../../common/auth";
import { VIDEO_SERVICES } from "../../services";

const VideoEdit = (props) => {
  const { request, video, cancel, accept } = props;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [loading, setLoading] = useState(false);
  const dataFetched = useRef(false);
  const [defaultData, setDefaultData] = useState("");
  useEffect(() => {
    if (!video || dataFetched.current) return;
    dataFetched.current = true;
    setLoading(true);
    VIDEO_SERVICES.edit({
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
      id: video?._id,
    })
      .then((data) => {
        if (data.code === 200) {
          setDefaultData(data?.data);
          setTitle(data?.data?.title || "");
          setDescription(data?.data?.description || "");
          toast(data.message);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          cancel();
        } else {
          toast(data.message);
          cancel();
        }
      })
      .catch((err) => {
        toast(err.message);
        cancel();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [video, dataFetched, LOGOUT_USER_SESSION, isUserSession, cancel]);

  const onSave = () => {
    if (!title.trim()) {
      return toast("Please enter video title");
    }
    if (!description.trim()) {
      return toast("Please enter video description");
    }
    setLoading(true);
    VIDEO_SERVICES.update({
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
      id: video?._id,
      title: title.trim(),
      description: description.trim(),
    })
      .then((data) => {
        if (data.code === 200) {
          toast(data.message);
          accept();
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
          cancel();
        } else {
          toast(data.message);
        }
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={request}
      onClose={cancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="videos-dialog"
    >
      {loading && <FullPageLoader />}
      <DialogTitle className="text-center" style={{ overflow: "hidden" }}>
        Edit <br /> <small>{video.title}</small>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth className="mt-20 mb-20">
          <TextField
            required
            id="video-edit-title"
            label="Title"
            value={title}
            onChange={(e) => {
              if (e.target?.value?.length <= 100) {
                setTitle(e.target?.value?.replace(/(<([^>]+)>)/gi, ""));
              }
            }}
          />
        </FormControl>
        <FormControl fullWidth className="mt-20 mb-20">
          <TextField
            multiline={true}
            minRows={3}
            maxRows={5}
            required
            id="video-edit-description"
            label="Description"
            value={description}
            onChange={(e) => {
              setDescription(e.target?.value?.replace(/(<([^>]+)>)/gi, ""));
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions className="videos-dialog-action">
        <Button
          variant="outlined"
          onClick={cancel}
          className="videos-dialog-btn  videos-btn videos-btn-secondary"
        >
          Cancel
        </Button>

        {title &&
        (defaultData?.title !== title ||
          defaultData?.description !== description) ? (
          <>
            <Button
              variant="outlined"
              onClick={onSave}
              className="videos-dialog-btn videos-btn videos-btn-primary"
            >
              Save
            </Button>
          </>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default VideoEdit;
