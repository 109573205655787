import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import VideoUploadButton from "../Uploader/VideoUploadButton";
import { SESSION_AUTH } from "../../common/auth";

const HeaderSidebar = (props) => {
  const { isUserSession } = SESSION_AUTH();
  const { setOpenSidebar } = props;
  const [activeClass, setActiveClass] = useState("");

  useEffect(() => {
    setActiveClass("active");
    return () => null;
  }, []);

  const closeSideBar = () => {
    setOpenSidebar(false);
  };

  return (
    <>
      <div className={`videos-layout-sidebar ${activeClass}`}>
        <div className="videos-layout-sidebar-inner">
          <div className="videos-layout-sidebar-header">
            <VideoUploadButton onCallBack={closeSideBar} classes="w-100" />
          </div>

          <div className="videos-layout-sidebar-body">
            {isUserSession && (
              <NavLink
                to="/dashboard"
                className="videos-layout-sidebar-link"
                onClick={closeSideBar}
              >
                Dashboard
              </NavLink>
            )}
            <NavLink
              to="/explore"
              className="videos-layout-sidebar-link"
              onClick={closeSideBar}
            >
              Explore
            </NavLink>
          </div>
        </div>
      </div>
      <div
        className="videos-layout-sidebar-overlay"
        onClick={closeSideBar}
      ></div>
    </>
  );
};

export default HeaderSidebar;
