import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { UploadIcon } from "../../icons";
import { isValidUrl } from "../../common/utltis";
import { VIDEO_TYPE_ALLOW } from "../../common/constants";

const VideoUploadReplace = (props) => {
  const { classes, onCallBack } = props;
  const [videoUrl, setVideoUrl] = useState("");
  const onFileChoose = (file) => {
    onCallBack(file, "file");
  };
  const urlUpload = async () => {
    if (!videoUrl) {
      return toast("Please paste video url");
    } else if (!isValidUrl(videoUrl)) {
      return toast("Video url is not valid");
    } else {
      return onCallBack(videoUrl, "url");
    }
  };
  return (
    <>
      <div className={`videos-uploadBox videos-uploadBox-withDrag ${classes}`}>
        <FileUploader
          className="videos-uploadBox-withDrag-frame"
          handleChange={onFileChoose}
          name="file"
          types={VIDEO_TYPE_ALLOW}
        />
        <div className="videos-uploadBox-withDrag-content">
          <Button
            variant="outlined"
            className="videos-btn videos-btn-md videos-btn-primary"
            type="button"
            startIcon={<UploadIcon />}
          >
            upload video for free
          </Button>
          <div className="videos-uploadBox-withDrag-content-info">
            You can also drag and drop a video file
          </div>
        </div>
      </div>
      <div className="videos-uploadBox-or">
        <span className="videos-uploadBox-or-text">
          <span className="videos-uploadBox-or-text-inner">OR</span>
        </span>
      </div>
      <div className={`videos-uploadBox-withUrl ${classes}`}>
        <div className="videos-uploadBox-withUrl-col">
          <input
            value={videoUrl}
            type="url"
            className="videos-uploadBox-withUrl-input"
            placeholder="Paste a video URL"
            onChange={(e) => setVideoUrl(e.target.value)}
          />
        </div>
        {videoUrl && (
          <Button
            variant="outlined"
            className="videos-btn videos-btn-secondary"
            type="button"
            onClick={urlUpload}
          >
            Upload
          </Button>
        )}
      </div>
    </>
  );
};

export default VideoUploadReplace;
