import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Menu, MenuItem } from "@mui/material";
import { useGlobalValue } from "../../GlobalContext";
import { CheckMark, GlobeIcon } from "../../icons";
import FullPageLoader from "../Common/FullPageLoader";
import { SESSION_AUTH } from "../../common/auth";
import { GOOGLE_LANGUAGE_LIST } from "../../common/constants";

const HeaderLanguage = () => {
  const { videosLang, setVideosLang } = useGlobalValue();
  const navigate = useNavigate();
  const [headerOrgEl, setHeaderOrgEl] = useState(null);
  const headerOrgOpen = Boolean(headerOrgEl);
  const scriptRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const { translateCookies, setTranslateCookies } = SESSION_AUTH();

  const headerOrgMenuOpen = (event) => {
    setHeaderOrgEl(event.currentTarget);
  };
  const headerOrgMenuClose = () => {
    setHeaderOrgEl(null);
  };
  useEffect(() => {
    if (translateCookies) {
      setLoading(true);
      if (translateCookies.indexOf("/") > -1) {
        let s = translateCookies.split("/");
        setVideosLang(s[s.length - 1]);
      }
      setTimeout(() => {
        setLoading(false);
      }, 2500);
    } else {
      setLoading(false);
    }
  }, [translateCookies, navigate]);

  useEffect(() => {
    if (!scriptRef.current) {
      const script = document.createElement("script");
      script.src =
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      document.body.appendChild(script);
      scriptRef.current = script;
      window.googleTranslateElementInit = googleTranslateElementInit;
    }
  }, []);
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  const switchLanguage = (item) => {
    headerOrgMenuClose();
    if (item?.lang) {
      setTranslateCookies("/en/" + item?.lang);
      let container = document.getElementById("google_translate_element");
      let select = container.getElementsByTagName("select")[0];
      select.value = item?.lang;
      select.dispatchEvent(new Event("change"));
    }
  };

  return (
    <>
      <Box id="google_translate_element" css={{ display: "none" }} />

      <div
        className={`videos-layout-header-right-item-btn ${
          headerOrgOpen ? "active" : ""
        }`}
        id="header-language-menu-button"
        aria-controls={headerOrgOpen ? "header-language-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={headerOrgOpen ? "true" : undefined}
        onClick={headerOrgMenuOpen}
      >
        <GlobeIcon />
        <div
          className="videos-layout-header-right-item-btn-text"
          translate="no"
        >
          {videosLang ? <>{videosLang}</> : <>EN</>}
        </div>
      </div>

      <Menu
        id="header-language-menu"
        anchorEl={headerOrgEl}
        open={headerOrgOpen}
        onClose={headerOrgMenuClose}
        MenuListProps={{
          "aria-labelledby": "header-language-menu-button",
        }}
        className="videos-layout-header-right-item-dropdown videos-layout-header-right-item-dropdown-language"
      >
        <div
          className="videos-layout-header-right-item-dropdown-language-header"
          translate="no"
        >
          Most Popular
        </div>
        <div className="videos-layout-header-right-item-dropdown-language-inner">
          {GOOGLE_LANGUAGE_LIST.filter((item) => item.popular).map(
            (item, key) => (
              <MenuItem
                key={key}
                className={`videos-layout-header-right-item-dropdown-language-item ${
                  videosLang === item?.lang ? "active" : ""
                } `}
                onClick={() => {
                  if (videosLang !== item?.lang) {
                    switchLanguage(item);
                  }
                }}
                title={item?.name}
                translate="no"
              >
                <div className="videos-layout-header-right-item-dropdown-language-item-check">
                  {videosLang === item?.lang && <CheckMark />}{" "}
                </div>
                {item?.name}
              </MenuItem>
            )
          )}
        </div>
        <div
          className="videos-layout-header-right-item-dropdown-language-header"
          translate="no"
        >
          All languages
        </div>
        <div className="videos-layout-header-right-item-dropdown-language-inner">
          {GOOGLE_LANGUAGE_LIST.map((item, key) => (
            <MenuItem
              key={key}
              className={`videos-layout-header-right-item-dropdown-language-item ${
                videosLang === item?.lang ? "active" : ""
              } `}
              onClick={() => {
                if (videosLang !== item?.lang) {
                  switchLanguage(item);
                }
              }}
              title={item?.name}
              translate="no"
            >
              <div className="videos-layout-header-right-item-dropdown-language-item-check">
                {videosLang === item?.lang && <CheckMark />}{" "}
              </div>
              {item?.name}
            </MenuItem>
          ))}
        </div>
      </Menu>

      {loading ? <FullPageLoader dark={true} /> : null}
    </>
  );
};

export default HeaderLanguage;
