import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { VIDEO_SERVICES } from "./services";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [loginRequest, setLoginRequest] = useState(false);
  const [videosLang, setVideosLang] = useState("en");
  const [videoRequestList, setVideoRequestList] = useState([]);
  const [videoListReload, setVideoListReload] = useState(null);
  const [videoProcess, setVideoProcess] = useState("");

  useEffect(() => {
    if (videoProcess) {
      let listData = [...videoRequestList];
      if (videoProcess?.percent) {
        listData[videoProcess.index]["process"] = videoProcess?.percent;
      }
      if (videoProcess?.hide) {
        listData[videoProcess.index]["hide"] = videoProcess?.hide;
      }
      if (videoProcess?.complete) {
        listData[videoProcess.index]["complete"] = videoProcess?.complete;
      }
      setVideoRequestList(listData);
    }
    // eslint-disable-next-line
  }, [videoProcess]);
  const videoUploadRequest = async (file, type, user, LOGOUT_USER_SESSION) => {
    const parma = new FormData();
    parma.append("userId", user.userId);
    parma.append("org", user?.org?.id);
    if (type === "file") {
      parma.append("video", file, file.name);
    } else {
      parma.append("video", file);
    }

    let listData = [
      ...videoRequestList,
      {
        id: new Date().getTime(),
        title: file?.name,
        size: file?.size,
        process: 0,
      },
    ];
    let index = listData.length - 1;
    setVideoRequestList(listData);

    const onUploadProgress = (data) => {
      let percent = Math.round((100 * data.loaded) / data.total);
      setVideoProcess({ index, percent });
    };

    try {
      VIDEO_SERVICES.upload(parma, onUploadProgress)
        .then((data) => {
          if (data.code === 200) {
            setVideoListReload(new Date());
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            toast(data.message);
          }
          setVideoProcess({ index, hide: true });
        })
        .catch((error) => {
          toast(error.message);
          setVideoProcess({ index, hide: true });
        })
        .finally(() => {});
    } catch (e) {
      toast("Error Something went wrong");
      setVideoProcess({ index, hide: true });
    }
  };
  return (
    <GlobalContext.Provider
      value={{
        loginRequest,
        setLoginRequest,
        videoRequestList,
        setVideoRequestList,
        videoUploadRequest,
        videoListReload,
        setVideoListReload,
        videosLang,
        setVideosLang,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalValue = () => useContext(GlobalContext);
