import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({
  title = "",
  description = "",
  type = "",
  name = "",
  image = "",
  url = "",
}) => {
  return (
    <Helmet prioritizeSeoTags>
      <title>{title ? title : "6ix Videos"}</title>
      <meta
        name="description"
        content={
          description ? description : "Livestream to millions of investors"
        }
      />
      <link
        rel="canonical"
        href={url ? url : process.env.REACT_APP_PLATFORM_URL}
      />
      <meta property="og:title" content={title ? title : "6ix Video"} />
      <meta
        property="og:description"
        content={
          description ? description : "Livestream to millions of investors"
        }
      />
      <meta
        property="og:url"
        content={url ? url : process.env.REACT_APP_PLATFORM_URL}
      />
      <meta
        property="og:image"
        content={
          image
            ? image
            : process.env.REACT_APP_PLATFORM_URL + "/images/social-banner.jpg"
        }
      />
      <meta property="og:type" content={type ? type : "website"} />
      <meta property="og:site_name" content="6ix Videos" />
      <meta property="og:locale" content="en_IN" />

      <meta name="twitter:creator" content={name ? name : "@6ix"} />
      <meta property="twitter:domain" content="videos.6ix.com" />
      <meta name="twitter:site" content="@6ix" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title || "6ix Videos"} />
      <meta
        name="twitter:description"
        content={
          description ? description : "Livestream to millions of investors"
        }
      />
      <meta
        name="twitter:image"
        content={
          image
            ? image
            : process.env.REACT_APP_PLATFORM_URL + "/images/social-banner.jpg"
        }
      />
    </Helmet>
  );
};

export default SEO;
