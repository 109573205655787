import { CircularProgress } from "@mui/material";

const FullPageLoader = (props) => {
  return (
    <div
      className={`videos-loader ${!props?.normal ? "videos-loader-fixed" : ""}  ${props?.dark ? "videos-loader-dark" : ""}`}
    >
      <CircularProgress size={90} thickness={3.8} color="inherit" />
    </div>
  );
};

export default FullPageLoader;
