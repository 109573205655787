import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { ClockIcon, LinkIcon } from "../../icons";
import { STATUS_LIST } from "../../common/constants";

const ExploreListItem = (props) => {
  const { actionCallback, itemData } = props;

  const actionMenuItem = (type) => {
    if (actionCallback) {
      actionCallback(type, itemData);
    }
  };
  return (
    <Box className="videos-layout-list-items-box">
      <div className="videos-layout-list-items-box-inner">
        <Link
          to={itemData?.videoLink}
          className="videos-layout-list-items-box-media"
          title={itemData?.title}
        >
          {itemData?.status === STATUS_LIST?.process?.value ? (
            <div className="videos-layout-list-items-box-media-process">
              <div className="videos-layout-list-items-box-media-process-text">
                <ClockIcon />
                Video publishing soon
              </div>
            </div>
          ) : itemData?.status === STATUS_LIST?.error?.value ? (
            <div className="videos-layout-list-items-box-media-process">
              <div className="videos-layout-list-items-box-media-process-text">
                <ClockIcon />
                Video uploading error
              </div>
            </div>
          ) : itemData?.thumb ? (
            <img src={itemData?.thumb} alt={itemData?.title} />
          ) : null}
          <div className="videos-layout-list-items-box-media-action">
            {itemData?.mediaProcess && (
              <div className="videos-layout-list-items-box-media-action-indicate">
                Process
              </div>
            )}
            {itemData?.status === STATUS_LIST?.active?.value && (
              <div className="videos-layout-list-items-box-media-action-views">
                {itemData?.views?.count || "0"} Views
              </div>
            )}
          </div>
        </Link>

        <div className="videos-layout-list-items-box-content">
          <Link
            to={itemData?.videoLink}
            className="videos-layout-list-items-box-title"
          >
            {itemData?.title}
          </Link>
          <div className="videos-layout-list-items-box-copy">
            <div className="videos-layout-list-items-box-copy-url">
              {itemData?.videoLink}
            </div>
            <div
              className="videos-layout-list-items-box-copy-btn"
              onClick={() => actionMenuItem("copy")}
            >
              <LinkIcon />
              Copy Link
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default ExploreListItem;
